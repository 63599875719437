import React from "react";
import styled from "styled-components";
import {Row, Col} from 'antd';
import moment from "moment";

export const IRListItem = ({item}) => {

  return (
    <>
      <Container>
        <Row>
          <Col span={6}>
            <ListNumber>{item.ir_index}</ListNumber>
          </Col>
          <Col span={12}>
            <a href={`/irpaper/${item.ir_index}`}>
              <ListTitle>{item.ir_title}</ListTitle>
            </a>
          </Col>
          <Col span={6}>
            <ListDate>{moment(item.created_date).format('YYYY-MM-DD')}</ListDate>
          </Col>
        </Row>
      </Container>
      <IRListItemLine />
    </>
  )
}

const Container = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ListNumber = styled.div`
  display: flex;
  justify-content: center;
  color: #b0b0b0;
  font-size: 1em;
  line-height: 1.45;
  letter-spacing: -0.02px;
  font-family: "NotoSansKR Medium",sans-serif;
`

const ListTitle = styled.div`
  color: #000000;
  font-size: 1em;
  line-height: 1.45;
  letter-spacing: -0.02px;
  font-family: "NotoSansKR Medium",sans-serif;
`

const ListDate = styled.div`
  color: #b0b0b0;
  font-size: 0.9em;
  line-height: 1.45;
  letter-spacing: -0.02px;
  font-family: "NotoSansKR Medium",sans-serif;
`

const IRListItemLine = styled.div`
  width: 100%;
  height: 1px;
  transform: rotate(-360deg);
  background-color: #bec2cc;
`



