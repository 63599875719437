import React from "react";
import styled from 'styled-components'
import DifferenceImg from '../../../media/images/Tech/DifferenceImg.webp'
import {useMediaQuery} from "react-responsive";
import withReveal from "react-reveal/withReveal";
import Fade from 'react-reveal/Fade';

export const Difference = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    isTabletOrMobile ?

    <Container isTabletOrMobile={isTabletOrMobile}>
      <MobileSection>
        <Title isTabletOrMobile={isTabletOrMobile}>
          인건비는 <GradientTitle>{"낮추고,\n"}</GradientTitle>
          배달 품질은 <GradientTitle>{"높이고"}</GradientTitle>
        </Title>

        {/*<ImageItem src={MobileDifferenceImg}/>*/}

        <SubTitle isTabletOrMobile={isTabletOrMobile}>{`자율주방시스템을 탑재한 트럭으로\n1인 운영이 가능합니다.\n\n또한, 이동중 요리하는 시스템으로 좋은 컨디션의 음식을 좋은 가격에 제공합니다.`}
        </SubTitle>
      </MobileSection>
    </Container>

      :

    <Container>

      <TextSection>
        <Title isTabletOrMobile={isTabletOrMobile}>
          인건비는 더욱 <GradientTitle>{"낮추고,\n"}</GradientTitle>
          배달 품질은 더욱 <GradientTitle>{"높이고"}</GradientTitle>
        </Title>
        <SubTitle isTabletOrMobile={isTabletOrMobile}>{`자율주방시스템을 탑재한 트럭으로 1인 운영이 가능합니다.\n또한, 이동중 요리하는 시스템으로 항상 최상의 컨디션의 음식을 합리적인 가격에 제공합니다.`}
        </SubTitle>
      </TextSection>

      <ImageItem src={DifferenceImg}/>

    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: ${(props) => props.isTabletOrMobile ? "15% 5%" : "10% 15%"};
  background-color: ${(props) => props.isTabletOrMobile ? "#F3F3F5" : "white"};;
`

const TextSection = styled.div`
  
`

const MobileSection = withReveal(styled.div`

`, <Fade bottom/>)

const Title = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Bold",sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "6.5vw" : "3.77vw"};
  color: #27251f;
  line-height: 1.41;
  letter-spacing: -0.5px;
  margin-bottom: ${(props) => props.isTabletOrMobile ? "7%" : "4%"};;
`

const GradientTitle = styled.span`
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SubTitle = styled.div`
  white-space: pre-line;
  font-size: ${(props) => props.isTabletOrMobile ? "4vw" : "1.43vw"};
  line-height: 1.5;
  color: #27251f;
  letter-spacing: -0.5px;
  font-family: "NotoSansKR Regular",sans-serif;
  margin-top: ${(props) => props.isTabletOrMobile ? "12px" : null};
`


const ImageItem = styled.img`
  width: 100%;
  padding-top: 7%;
`
