import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';

export const EfficiencyProfitability = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>

      <Title isTabletOrMobile={isTabletOrMobile}>{`지속가능한\n`}
        <GradientTitle>{`매장 운영 시스템`}</GradientTitle>
      </Title>

      <CardsSection isTabletOrMobile={isTabletOrMobile}>
        <CardContainer isTabletOrMobile={isTabletOrMobile}>
          <CardTitle isTabletOrMobile={isTabletOrMobile}>
            체계적인 주방
          </CardTitle>

          <CardBody isTabletOrMobile={isTabletOrMobile}>
            {`복잡하지 않은 레시피와 자동 조리 로봇으로 높은 효율과, 체계적인 주방 시스템이 구축됩니다.`}
          </CardBody>
        </CardContainer>

        <CardContainer isTabletOrMobile={isTabletOrMobile}>
          <CardTitle isTabletOrMobile={isTabletOrMobile}>
            낮은 인건비
          </CardTitle>

          <CardBody isTabletOrMobile={isTabletOrMobile}>
            {`자동조리 로봇으로 1인 운영이 가능한 매장 시스템을 구축해, 낮은 인건비를 약속합니다.`}
          </CardBody>
        </CardContainer>

        <CardContainer isTabletOrMobile={isTabletOrMobile}>
          <CardTitle isTabletOrMobile={isTabletOrMobile}>
            쉬운 레시피
          </CardTitle>

          <CardBody isTabletOrMobile={isTabletOrMobile}>
            {`신스타 프리젠츠는 끊임없는 연구를 통해 누구나 조리 가능한 쉽고 맛있는 레시피를 제공합니다.`}
          </CardBody>
        </CardContainer>
      </CardsSection>

    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  padding: ${(props) => props.isTabletOrMobile ? "10% 5%" : "10% 12%"};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = withReveal(styled.div`
  white-space: pre-line;
  font-size: ${(props) => props.isTabletOrMobile ? "6.5vw" : "3.6rem"};
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.39;
  letter-spacing: -0.5px;
  margin-bottom: 60px;
  text-align: center;
`, <Fade bottom/>);

const GradientTitle = styled.div`
  background-image: linear-gradient(to right, #ff1400 -3%, #ff3d00 105%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const CardsSection = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isTabletOrMobile ? "column" : "row"};
`

const CardContainer = withReveal(styled.div`
  width: ${(props) => props.isTabletOrMobile ? "48.6vw" : "282px"};
  height: ${(props) => props.isTabletOrMobile ? "56vw" : "350px"};
  box-shadow: 0 7px 10px 2px rgba(66, 71, 81, 0.3);
  margin: 12.5px;
  border-radius: 20px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`, <Fade bottom/>);

const CardTitle = styled.div`
  font-family: "NotoSansKR Bold", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "4.2vw" : "30px"};
  line-height: 1.5;
  letter-spacing: -0.5px;
  text-align: ${(props) => props.isTabletOrMobile ? "left" : "center"};
  // margin-top: ${(props) => props.isTabletOrMobile ? null : null};
  margin-bottom: ${(props) => props.isTabletOrMobile ? "9%" : "40px"};
  color: #27251f;
  width: 100%;
`

const CardBody = styled.div`
  font-size: ${(props) => props.isTabletOrMobile ? "4vw" : "20px"};
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.5;
  text-align: left;
  color: #27251f;
  opacity: 60%;
`