import React from "react";
import {Row, Col} from 'antd';
import styled from 'styled-components'
import {NewsCard} from "../../atoms/NewsCard";
import {useMediaQuery} from "react-responsive";
import {NewsDataAtom} from "../../../recoil";
import {useRecoilValue} from "recoil";
import {NewsData} from "../../../configs/NewsData";

export const NewsCategory = ({year}) => {
  const [newsItem, setNewsItem] = React.useState([]);
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});
  const NewsDataVal = useRecoilValue(NewsDataAtom);

  React.useEffect(() => {
    let newsArr = [];

    if(NewsDataVal.length > 0) {
      for(let i of NewsDataVal){
        if(year === i.year){
          newsArr.unshift(i);
        }
      }

      setNewsItem(newsArr);
    } else {
      // ERROR 상황에서의 데이터 정렬
      for(let i of NewsData){
        if(year === i.year){
          newsArr.unshift(i);
        }
      }

      setNewsItem(newsArr);
    }
  }, [])

  return (
    <Container>

      <Row gutter={[48, 16]}>
        <Col xs={24} sm={24} md={7}>
          <Year isTabletOrMobile={isTabletOrMobile}>{year}</Year>
        </Col>

        <Col  xs={24} sm={24} md={15}>
          {newsItem.map(item => {
            return  <NewsCard item={item} />
          })}
        </Col>
      </Row>

    </Container>
  )
}

const Container = styled.div`
  padding: 0 11%;
  margin-bottom: 2.5rem;
`

const Year = styled.div`
  font-family: "DMSans Regular",sans-serif;
  font-size: 1.4rem;
  color: #656565;
  display: flex;
  justify-content: ${(props) => props.isTabletOrMobile ? "flex-start" : "flex-end"};
  line-height: 1.2;
`