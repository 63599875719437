import React from "react";
import styled from 'styled-components'
import { Chrono } from "react-chrono";
import {useMediaQuery} from "react-responsive";
import {TimeLineContents, TimeLineData} from "../../../configs/TimeLineData";

export const Footprint = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});
  const [reverseTimeLineData, setReverseTimeLineData] = React.useState([]);
  const [reverseTimeLineContents, setReverseTimeLineContents] = React.useState([]);

  React.useEffect(() => {
    setReverseTimeLineData(TimeLineData.reverse());
    setReverseTimeLineContents(TimeLineContents.reverse());

    return () => {
      setReverseTimeLineData(TimeLineData.reverse());
      setReverseTimeLineContents(TimeLineContents.reverse());
    }
  }, [])

  return(
    !isTabletOrMobile &&
    <Container>

      <Title isTabletOrMobile={isTabletOrMobile}>회사 연혁</Title>

      <TimeLineWrapper isTabletOrMobile={isTabletOrMobile}>
        <Chrono
          items={reverseTimeLineData}
          mode="VERTICAL"
          cardHeight={10}
          borderLessCards
          hideControls
          allowDynamicUpdate={true}
          disableClickOnCircle={true}
          theme={{
            primary: "#424751",
            secondary: "transparent",
            cardBgColor: "transparent",
            cardForeColor: "#424751",
            titleColor: "#424751"
          }}
        >
          {reverseTimeLineContents.map(card => {
            return(
              <ChronoCard>
                {card.cardText}
              </ChronoCard>
            )
          })}
        </Chrono>
      </TimeLineWrapper>

    </Container>
  )
}

const Container = styled.div`
  padding: 10%;
  background: linear-gradient(180deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 9.38%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const TimeLineWrapper = styled.div`
  width: ${(props) => props.isTabletOrMobile ? '100%' : '50%'};
`

const Title = styled.div`
  font-size: ${(props) => props.isTabletOrMobile ? "2.2rem" : "2.9rem"};
  font-family: "NotoSansKR Bold", sans-serif;
  margin-bottom: 6%;
  line-height: 1.45;
`

const ChronoCard = styled.div`
  width: 100%;
  white-space: pre-line;
  font-family: "DMSans Regular", sans-serif;
  line-height: 1.62;
  letter-spacing: 0.09px;
  font-size: 15px;
`