import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { VisibleEmailModalAtom } from "../../../recoil";
import { useSetRecoilState } from "recoil";
import { useMediaQuery } from "react-responsive";

export const ShareFooter = () => {
  const SetVisibleEmailModalAtom = useSetRecoilState(VisibleEmailModalAtom);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1124px)" });

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>
      <Row gutter={[0, 24]}>
        {/* CO INFORMATION */}
        <Col xs={24} sm={24} md={12}>
          <ContactTitle>COMPANY</ContactTitle>
          <CoInfoText>
            Copyright ⓒ SHIN STARR PRESENTS Republic of Korea
          </CoInfoText>
          <CoInfoText>사업자등록번호 : 780-86-01695</CoInfoText>
          <CoInfoText>
            개인정보처리방침 :{" "}
            <StyledLink
              href="https://shinstarr.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://shinstarr.com/privacy
            </StyledLink>
          </CoInfoText>
          {/* <CoInfoText>
            개인정보처리방침 : https://shinstarr.com/privacy
          </CoInfoText> */}
          <CoInfoText>
            주소 : 서울 영등포구 양산로91 리드원센터 B109호
          </CoInfoText>

          {/* 추후 업데이트 예정 */}
          {/*<IconSection>*/}
          {/*  <a href={"/"}>*/}
          {/*    <InstagramFilled style={{color: 'white', marginRight: '0.7em', fontSize: '1.5em'}}/>*/}
          {/*  </a>*/}
          {/*  <a href={"/"}>*/}
          {/*    <YoutubeFilled style={{color: 'white', fontSize: '1.5em'}}/>*/}
          {/*  </a>*/}
          {/*</IconSection>*/}
        </Col>

        {/* CONTACT */}
        <Col xs={24} sm={24} md={12}>
          <ContactTitle>CONTACT</ContactTitle>
          <ContactTextSection>
            <ContactEmail onClick={() => SetVisibleEmailModalAtom(true)}>
              {"info@shinstarr.com"}
            </ContactEmail>
            <ContactText>PHONE +822 336 5982</ContactText>
            <ContactText>FAX +822 336 5983</ContactText>

            {/* 추후 각 부서별 이메일 연결 예정 */}
            {/*<Divide>{" | "}</Divide>*/}
            {/*<ContactText>{"BRAND"}</ContactText>*/}
            {/*<Divide>{" | "}</Divide>*/}
            {/*<ContactText>{"PR"}</ContactText>*/}
            {/*<Divide>{" | "}</Divide>*/}
            {/*<ContactText>{"IR"}</ContactText>*/}
            {/*<Divide>{" | "}</Divide>*/}
            {/*<ContactText>{"HR"}</ContactText>*/}
          </ContactTextSection>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.footer`
  width: 100%;
  height: ${(props) => (props.isTabletOrMobile ? "100%" : "290px")};
  /* background-color: #e42313; */
  background-color: #121317;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
`;

const CoInfoText = styled.div`
  font-family: "DMSans Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.07px;
  color: #fff;
  margin-bottom: 10px;
`;

const ContactTitle = styled.div`
  font-family: "DMSans Regular", sans-serif;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.54px;
  margin-bottom: 2%;
`;

const ContactTextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactText = styled.span`
  font-family: "DMSans Regular", sans-serif;
  font-size: 15px;
  letter-spacing: -0.07px;
  color: #fff;
  margin-bottom: 2%;
`;

const ContactEmail = styled.span`
  font-family: "DMSans Regular", sans-serif;
  font-size: 15px;
  letter-spacing: -0.07px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 2%;
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`;

// const Divide = styled.span`
//   margin: 0 5px;
//   color: #fff;
// `

// const IconSection = styled.div`
//   margin-bottom: 2%;
// `
