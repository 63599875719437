import { Routes, Route } from "react-router-dom";
import React from "react";

// Pages
import Tech from "./pages/Tech";
import About from "./pages/About";
import Brand from "./pages/Brand";
import News from "./pages/News";
import InvestorRelations from "./pages/InvestorRelations";
import IRPaper from "./pages/IRPaper";
import { SendEmailModal } from "./atoms/SendEmailModal";
import { Hack } from "./pages/Tech/Hack/Hack";
import HackTwo from "./pages/Tech/HackTwo";
import Privacy from "./pages/Privacy";

export default function App() {
  return (
    <>
      <Routes>
        <Route exact={true} path={"/"} element={<Tech />} />
        {/* <Route exact={true} path={"/about"} element={<About />} />
        <Route exact={true} path={"/brand"} element={<Brand />} />
        <Route exact={true} path={"/news"} element={<News />} /> */}
        <Route exact={true} path={"/privacy"} element={<Privacy />} />
        {/* <Route
          exact={true}
          path={"/investor-relations"}
          element={<InvestorRelations />}
        /> */}
        {/* <Route exact={true} path={"/irpaper/:id"} element={<IRPaper />} />
        <Route exact={true} path={"/10qjszmfflrdlwngh"} element={<Hack />} />
        <Route
          exact={true}
          path={"/10qjsqwerwqefasdfgasdfasefzmfflrdlwngh"}
          element={<HackTwo />}
        /> */}
      </Routes>
      <SendEmailModal />
    </>
  );
}
