import {atom} from "recoil";

// 모바일 환경에서 Drawer 기능
export const DrawerVisibleAtom = atom({
  key: 'drawer-visible-atom',
  default: false
})

// 언어 변경
export const ChangeLanguageAtom = atom({
  key: 'change-language-atom',
  default: 'kr'
})

// Video Modal
export const VisibleVideoModalAtom = atom({
  key: 'visible-video-modal-atom',
  default: {visible: false, videoId: ''}
})

// Email Visible
export const VisibleEmailModalAtom = atom({
  key: 'visible-email-modal-atom',
  default: false
})

// News Data
export const NewsDataAtom = atom({
  key: 'news-data-atom',
  default: []
})

// IR Data
export const IRDataAtom = atom({
  key: 'ir-data-atom',
  default: []
})

