import React, {useRef} from "react";
import styled from "styled-components";
import {Button, Modal} from 'antd';
import emailjs from "@emailjs/browser";
import {useRecoilState} from "recoil";
import {VisibleEmailModalAtom} from "../../recoil";

export const SendEmailModal = () => {
  const [visibleEmailModal, setVisibleEmailModal] = useRecoilState(VisibleEmailModalAtom)
  const form = useRef();
  const nameInput = useRef();
  const emailInput = useRef();
  const messageInput = useRef();

  const resetInputField = () => {
    nameInput.current.value = '';
    emailInput.current.value = '';
    messageInput.current.value = '';
  }

  const handleOk = () => {
    if(emailInput.current.value.length > 0 && messageInput.current.value.length > 0){
      sendEmail();
    }
    setVisibleEmailModal(false);
    resetInputField();
  };

  const handleCancel = () => {
    setVisibleEmailModal(false);
    resetInputField();
  };

  const sendEmail = () => {
    // e.preventDefault();

    emailjs.sendForm('service_lgifcce', 'template_9o5bqws', form.current, 'user_EAvZZOc3DBf8lVwE5BbeI')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Modal
      visible={visibleEmailModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Send"
      cancelText="Cancel"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}
                style={{backgroundColor: '#DA291C', borderColor: '#DA291C'}}>
          Send
        </Button>
      ]}
    >

      <FormContainer ref={form}>
        <InputLabel>Name
        </InputLabel>
        <FormInput ref={nameInput} type="text" name="from_name" placeholder={'Input your Name'}/>

        <InputLabel>Email
          <RequiredMark>*</RequiredMark>
        </InputLabel>
        <FormInput ref={emailInput} type="email" name="reply_to" placeholder={'Input your Email'}/>

        <InputLabel>Message
          <RequiredMark>*</RequiredMark>
        </InputLabel>
        <FormTextArea ref={messageInput} name="message" placeholder={'Input your Message'}/>
      </FormContainer>

    </Modal>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10% 0;
`

const InputLabel = styled.div`
  margin-bottom: 1%;
  font-family: "DMSans Bold", sans-serif;
  font-size: 15px;
`

const FormInput = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 3%;
  padding: 0 2%;
  font-size: 16px; 
`

const FormTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 2%;
  font-size: 16px;
`

const RequiredMark = styled.span`
  color: red;
`

//   <Modal
// visible={visibleEmailModal}
// onOk={handleOk}
// onCancel={handleCancel}
// okText="Send"
// cancelText="Cancel"
// footer={[
//     <Button key="back" onClick={handleCancel}>
//       Cancel
//     </Button>,
// <Button key="submit" type="primary" onClick={handleOk} style={{backgroundColor: '#DA291C', borderColor: '#DA291C'}}>
//   Send
// </Button>
// ]}
// >
// <Form ref={form}>
//   <Form.Item name={"from_name"} label="Name" labelCol={{span: 24}}>
// <Input/>
// </Form.Item>
//
// <Form.Item name="reply_to" label="Email" labelCol={{span: 24}} >
//   <Input/>
// </Form.Item>
//
// <Form.Item name={"message"} label="Message" labelCol={{span: 24}}>
//   <Input.TextArea style={{height: '150px'}} />
// </Form.Item>
//
// </Form>
// </Modal>