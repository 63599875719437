import React, {useState} from "react";
import {Drawer, Dropdown, Menu} from 'antd';
import {useRecoilState} from "recoil";
import {DrawerVisibleAtom} from "../../../recoil";
import styled from "styled-components";
import upBluImg from "../../../media/icons/arrows/up-red.png";
import downBluImg from "../../../media/icons/arrows/down-red.png";
import upGryImg from "../../../media/icons/arrows/up-gry.png";
import downGryImg from "../../../media/icons/arrows/down-gry.png";
// import languageImg from "../../../media/icons/ic-language.png";
import {Link} from "react-router-dom";

export const SideNav = ({navPath}) => {
  const [drawerVisible, setDrawerVisible] = useRecoilState(DrawerVisibleAtom);
  // const [lang, setLang] = useRecoilState(ChangeLanguageAtom);
  const [changeArrowIcon, setChangeArrowIcon] = useState(false);

  const onClose = () => {
    setDrawerVisible(false);
  }

  const onVisibleChange = (data) => {
    setChangeArrowIcon(data);
  }

  const menu = (
    <Menu style={{background: 'rgba(255, 255, 255, 0.9)'}}>
      <Menu.Item key={"sideNavNews"} style={{height: '9vw'}}>
        <Link to={'/news'} onClick={() => onClose()}>
          <NavTitle>NEWS</NavTitle>
        </Link>
      </Menu.Item>
      <Menu.Item key={"sideNavIR"} style={{height: '9vw'}}>
        <Link to={'/investor-relations'} onClick={() => onClose()}>
          <NavTitle>IR</NavTitle>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return(
    <Drawer
      title=" "
      placement={'left'}
      closable={false}
      onClose={onClose}
      visible={drawerVisible}
      key={'Left-Drawer'}
      contentWrapperStyle={{width: '80%'}}
      drawerStyle={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
      headerStyle={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottom: '1px solid #a6aab6' }}

    //   extra={
    //   <ExtraSection>
    //     <LanguageText>{lang === 'kr' ? 'KR' : 'EN'}</LanguageText>
    //     <LanguageIcon src={languageImg} onClick={() => lang === 'kr' ? setLang('en') : setLang('kr')}/>
    //   </ExtraSection>
    // }
    >

      <NavList>
        <Link to={'/'} onClick={() => onClose()}>
          <NavTitle navPath={navPath === 'tech'}>TECH</NavTitle>
        </Link>
        <Link to={'/about'} onClick={() => onClose()}>
          <NavTitle navPath={navPath === 'about'}>ABOUT</NavTitle>
        </Link>
        <Link to={'/brand'} onClick={() => onClose()}>
          <NavTitle navPath={navPath === 'brand'}>BRAND</NavTitle>
        </Link>
        <Dropdown overlay={menu}  placement="bottomCenter" onVisibleChange={visible => onVisibleChange(visible)} trigger={'click'}>
          <NavTitle navPath={navPath === 'media'}>MEDIA{navPath === 'media' ?
            <ArrowImg src={changeArrowIcon ? upBluImg : downBluImg}/> :
            <ArrowImg src={changeArrowIcon ? upGryImg : downGryImg}/>}
          </NavTitle>
        </Dropdown>
      </NavList>

    </Drawer>
  )
}

const NavList = styled.div`
  display: flex;
  height: 37%;
  flex-direction: column;
  justify-content: space-between;
`

const NavTitle = styled.span`
  font-size: 5vw;
  font-weight: 500;
  font-family: "DMSans Medium",sans-serif;
  color: ${(props) => props.navPath ? '#DA291C' : '#424751'};
  letter-spacing: 1.54px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ArrowImg = styled.img`
  width: 0.6em;
  margin-left: 0.3em;
`

// const ExtraSection = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 47px
//
// `
//
// const LanguageIcon = styled.img`
//   width: 1.5em;
//
// `
//
// const LanguageText = styled.span`
//   color: #424751;
//   font-family: "DMSans Medium",sans-serif;
//   font-size: 0.9em;
//   font-weight: 500;
//   letter-spacing: 1.43px;
//
// `