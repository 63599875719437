import React from "react";
import styled from 'styled-components'
import {Row, Col} from 'antd';
import withReveal from "react-reveal/withReveal";
import Fade from 'react-reveal/Fade';
import {useMediaQuery} from "react-responsive";

// ICONS
import ICAi from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-ai.webp"
import ICApp from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-app.webp"
import ICChicken from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-chicken.webp"
import ICDelivery from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-delivery.webp"
import ICEco from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-eco.webp"
import ICMan from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-man.webp"
import ICPickup from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-pickup.webp"
import ICTruck from "../../../media/images/Tech/MobileAutoKitchenIcons/ic-truck.webp"


export const MobileAutoKitchen = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    isTabletOrMobile ?

      <Container isTabletOrMobile={isTabletOrMobile}>

        {/* ****************************** TITLE ****************************** */}
        <TitleSection>
          <MobileTitle isTabletOrMobile={isTabletOrMobile}>
            모바일 자동화 키친
            <GradientTitle>{`\nCook-en-route`}</GradientTitle>
          </MobileTitle>
        </TitleSection>

        {/* ****************************** ICON & TEXT ****************************** */}
        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICTruck} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle
                isTabletOrMobile={isTabletOrMobile}>{`자율주행 & 자동조리\n한식 푸드 트럭`}
              </CardTitle>
            </MobileCardSection>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICPickup} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {`비대면 무인 픽업존`}
              </CardTitle>
            </MobileCardSection>
          </Col>
        </Row>

        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICDelivery} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {`탈중앙화 딜리버리`}
              </CardTitle>
            </MobileCardSection>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICAi} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle
                isTabletOrMobile={isTabletOrMobile}>
                {`주문 예측 및\n배차 알고리즘`}
              </CardTitle>
            </MobileCardSection>
          </Col>
        </Row>

        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICApp} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {`앱 연동`}
              </CardTitle>
            </MobileCardSection>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICMan} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {`단 한 명으로 운영`}
              </CardTitle>
            </MobileCardSection>
          </Col>
        </Row>

        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICEco} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle
                isTabletOrMobile={isTabletOrMobile}>
                {`친환경 &\n사회적책임 실천`}
              </CardTitle>
            </MobileCardSection>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <MobileCardSection>
              <IconSection isTabletOrMobile={isTabletOrMobile}>
                <CardIcon src={ICChicken} isTabletOrMobile={isTabletOrMobile}/>
              </IconSection>
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {`맛있는 한식!`}
              </CardTitle>
            </MobileCardSection>
          </Col>
        </Row>

      </Container>

      :

      <Container isTabletOrMobile={isTabletOrMobile}>

        {/* ****************************** TITLE ****************************** */}
        <TitleSection>
          <Title isTabletOrMobile={isTabletOrMobile}>
            모바일 자동화 키친
            <GradientTitle>{`\nCook-en-route`}</GradientTitle>
          </Title>
        </TitleSection>

        {/* ****************************** ICON & TEXT ****************************** */}
        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICTruck} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle
              isTabletOrMobile={isTabletOrMobile}>{`세계 최초 무인 자동화 주방\n탑재 한식 배달트럭`}</CardTitle>
            <Cardbody isTabletOrMobile={isTabletOrMobile}>{`신스타프리젠츠가 개발한\n무인 자동화 주방 탑재 푸드트럭`}</Cardbody>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICPickup} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle isTabletOrMobile={isTabletOrMobile}>{`비대면 무인 픽업존`}</CardTitle>
            <Cardbody isTabletOrMobile={isTabletOrMobile}>{`비대면 음식 Pick-Up으로\n보다 안전하게 식사!`} </Cardbody>
          </Col>
        </Row>

        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICDelivery} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle isTabletOrMobile={isTabletOrMobile}>{`탈중앙화 딜리버리`}</CardTitle>
            <Cardbody isTabletOrMobile={isTabletOrMobile}>{`기존 Uber Eats 사업모델 대비\n효율적인 비용 구조`}</Cardbody>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICAi} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle
              isTabletOrMobile={isTabletOrMobile}>{`주문 예측 및 배차 알고리즘`}</CardTitle>
            <Cardbody
              isTabletOrMobile={isTabletOrMobile}>{`실시간 조리 현황, 식자재 재고와 함께\n교통상황 및 과거주문기록등에\n기반한 주문예측 및 조리 시스템 탑재`}</Cardbody>
          </Col>
        </Row>

        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICApp} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle isTabletOrMobile={isTabletOrMobile}>{`앱 연동`}</CardTitle>
            <Cardbody
              isTabletOrMobile={isTabletOrMobile}>{`실시간 트래픽 및 가용 트럭\n모니터링 온라인주문 및 실시간\n배달 프로세스 확인 가능\n(App / Web / SNS / IoT 주문)`}</Cardbody>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICMan} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle isTabletOrMobile={isTabletOrMobile}>{`단 한 명으로 운영`}</CardTitle>
            <Cardbody isTabletOrMobile={isTabletOrMobile}>트럭 드라이버가 곧 레스토랑 점장!</Cardbody>
          </Col>
        </Row>

        <Row gutter={[48]}>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICEco} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle
              isTabletOrMobile={isTabletOrMobile}>{`친환경 & 사회적책임 실천`}</CardTitle>
            <Cardbody isTabletOrMobile={isTabletOrMobile}>{`친환경 제품의 가벼운 포장 사용으로\n사회공헌 및 소외계층 지원 활동`}</Cardbody>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <IconSection isTabletOrMobile={isTabletOrMobile}>
              <CardIcon src={ICChicken} isTabletOrMobile={isTabletOrMobile}/>
            </IconSection>
            <CardTitle isTabletOrMobile={isTabletOrMobile}> {`맛있는 한식!`}</CardTitle>
            <Cardbody isTabletOrMobile={isTabletOrMobile}>{`Korean is new Black!\n언제나 신선하고 맛있는 한식을 요리합니다.`}</Cardbody>
          </Col>
        </Row>

      </Container>
  )
}

const Container = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? "20% 5%" : "5% 15%"};
  overflow: hidden;
`

const TitleSection = styled.div`

`

const MobileTitle = withReveal(styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "6.5vw" : "3.5rem"};
  color: #282b31;
  line-height: 1.39;
  letter-spacing: -0.5px;
  margin-bottom: ${(props) => props.isTabletOrMobile ? "15%" : "4%"};
  text-align: ${(props) => props.isTabletOrMobile ? "center" : "left"};
`, <Fade bottom/>)

const Title = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "5.2vw" : "3.77vw"};
  color: #282b31;
  line-height: 1.39;
  letter-spacing: -0.5px;
  margin-bottom: ${(props) => props.isTabletOrMobile ? "15%" : "4%"};
  text-align: ${(props) => props.isTabletOrMobile ? "center" : "left"};
`

const GradientTitle = styled.span`
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const MobileCardSection = withReveal(styled.div`

`, <Fade bottom/>)

const IconSection = styled.div`
  display: flex;
  justify-content: ${(props) => props.isTabletOrMobile ? "center" : "left"};
`

const CardIcon = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "12.2vw" : "4.5rem"};
  margin-bottom: 5%;
`

const CardTitle = styled.div`
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "3.7vw" : "1.43vw"};
  font-weight: bold;
  white-space: pre-line;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #282b31;
  text-align: ${(props) => props.isTabletOrMobile ? "center" : "left"};
  margin-bottom: ${(props) => props.isTabletOrMobile ? "38px" : null};;
`

const Cardbody = styled.div`
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "1rem" : "1.43vw"};
  font-weight: bold;
  white-space: pre-line;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #7d7c79;
  margin-bottom: 10%;
`