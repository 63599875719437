import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import ShinStarrLogo from '../../../media/images/About/faviconImage.webp'

export const AboutShinStarr = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>

      {/* LOGO IMAGE */}
      <ImageSection>
        <LogoImage src={ShinStarrLogo}/>
      </ImageSection>

      {/* TEXT */}
      <TitleSection isTabletOrMobile={isTabletOrMobile}>
        <Title isTabletOrMobile={isTabletOrMobile}>{
          isTabletOrMobile ?
            `푸드테크를 이용한\n자율주방시스템`
            :
          `이동형 무인자동화키친`
        }</Title>

        <TextBody isTabletOrMobile={isTabletOrMobile}>
          {
            isTabletOrMobile ?
              `신스타 프리젠츠는 ‘자율주방시스템’을 이용하여 한국 외식산업의 문제점인 ‘체계화 되어 있지 않은 주방’을 해결하고, 한식을 세계에 널리 퍼뜨리기 위해 나아가는 한국 1세대 푸드테크 회사입니다.`
              :
              `신스타프리젠츠는 ‘무인 자동화키친’을 이용하여 한식 외식산업의 고질적 문제점인\n‘체계화 되어 있지 않은 주방과 높은 인건비’을 해결하고, 한식 외식 프랜차이즈 사업을\n세계에 널리 퍼뜨리기 위해 나아가는 한국 1세대 푸드테크 회사입니다.`}

        </TextBody>
      </TitleSection>

    </Container>
  )
}

const Container = styled.div`
  background-image: linear-gradient(to bottom, rgba(190, 194, 204, 0) 46%, rgba(190, 194, 204, 0.2) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.isTabletOrMobile ? "100px 10%;" : "10%" };
  overflow: hidden;
  height: 100vh;
`

const ImageSection = styled.div`
  width: 100px;
`

const LogoImage = styled.img`
  width: 100%;
  margin-bottom: 17px;
  animation: small 2s cubic-bezier(0.645, 0.045, 0.355, 1.000);

  @keyframes small {
    0% {
      transform: matrix(7, 0, 0, 7, 0, 230);
    }
    
    10% {
      transform: matrix(7, 0, 0, 7, 0, 230);
    }

    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }
`

const TitleSection = styled.div`
  animation: ${(props) => props.isTabletOrMobile ? 'textUp 2s cubic-bezier(0.734, 0.045, 0.355, 1.000)' : 'textUp 2s cubic-bezier(0.734, 0.045, 0.355, 1.000)'}  ;
  position: relative;

  @keyframes textUp {
    0% {
      top: 600px;
      opacity: 0;
    }

    100% {
     top: 0;
      opacity: 1;
    }
  }
`

const Title = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: ${(props) => props.isTabletOrMobile ? "8.5vw" : "3.6rem" };
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.39;
  letter-spacing: -0.5px;
  margin-bottom: 4%;
  color: #27251F
`

const TextBody = styled.div`
  text-align: center;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size:  ${(props) => props.isTabletOrMobile ? "4vw" : "1.4rem" };
  white-space: pre-line;
  color: #27251F;
  opacity: 80%;
`
