import React from "react";
import styled from "styled-components";
import {IRListItem} from "../../atoms/IRListItem";
import {Pagination} from "./Pagination";
import axios from "axios";
import {API_URL} from "../../../configs/Util";
import {IRInfoData} from "../../../configs/IRInfoData";

export const IRList = () => {
  const [irPosts, setIrPosts] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const pageStartIndex = (page - 1) * limit;

  const onIRQuery = () => {
    axios.get(`${API_URL}/ir`).then(res => {
      setIrPosts(res.data.data.reverse())
    }).catch(error => {
      setIrPosts(IRInfoData.reverse())
      console.log("IR QUERY ERROR => ", error)
    })
  }

  React.useEffect(() => {
    onIRQuery();
  }, [])

  return (
    <Container>

      <SharpLineSection>
        <ShapeLine/>
      </SharpLineSection>

      {irPosts.slice(pageStartIndex, pageStartIndex + limit).map(item => {
        return <IRListItem item={item} key={`${item.ir_index}${item.ir_title}`}/>
      })}

      <Pagination
        total={irPosts.length}
        limit={limit}
        page={page}
        setPage={setPage}
      />

    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 12%;
`

const ShapeLine = styled.div`
  width: 100%;
  height: 2px;
  transform: rotate(-360deg);
  background-image: linear-gradient(to right, rgba(66, 71, 81, 0) 0%, #424751 8%, #424751 92%, rgba(66, 71, 81, 0) 100%);
`

const SharpLineSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`