import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import EfficientKitchenImg from "../../../media/images/Tech/EfficientKitchenImg.webp"
import MobileEfficientKitchenImg from '../../../media/images/Tech/MobileEfficientKitchenImg.png'
import withReveal from "react-reveal/withReveal";
import Fade from 'react-reveal/Fade';

export const EfficientKitchen = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    isTabletOrMobile ?

      <Container isTabletOrMobile={isTabletOrMobile}>
        <MobileSection>
          <TextSection isTabletOrMobile={isTabletOrMobile}>
            <Title isTabletOrMobile={isTabletOrMobile}>
              타사 대비
              <GradientTitle>{"\n낮은 투자, 높은 수익률"}</GradientTitle>
            </Title>
          </TextSection>

          <ImageSection>
            <GraphImage isTabletOrMobile={isTabletOrMobile} src={MobileEfficientKitchenImg}/>
          </ImageSection>

          {/*<MobileSubTitle>{*/}
          {/*  `효율적인 주방 시스템으로 기존 패스트푸드 프랜차이즈 매장의 시스템 대비 낮은 투자와 투자수익률을 창출합니다.`}*/}
          {/*</MobileSubTitle>*/}
        </MobileSection>
      </Container>

      :

      <Container isTabletOrMobile={isTabletOrMobile}>

        <TextSection isTabletOrMobile={isTabletOrMobile}>
          <Title isTabletOrMobile={isTabletOrMobile}>
            타사 대비
            <GradientTitle>{"\n낮은 투자, 높은 수익률"}</GradientTitle>
          </Title>
          <SubTitle isTabletOrMobile={isTabletOrMobile}>
            {`신스타프리젠츠만의 효율적인 주방 시스템으로\n기존 패스트푸드 프랜차이즈 매장의 시스템 대비\n낮은 투자와 높은 수익률을 창출합니다.`}
          </SubTitle>
        </TextSection>

        <ImageSection>
          <GraphImage src={EfficientKitchenImg}/>
        </ImageSection>

        {isTabletOrMobile &&
          <MobileSubTitle>{
            `효율적인 주방 시스템으로 기존 패스트푸드 프랜차이즈 매장의 시스템 대비 낮은 투자와 투자수익률을 창출합니다.`}
          </MobileSubTitle>
        }

      </Container>
  )
}

const Container = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? "25% 5%" : "10% 15%"};
`

const MobileSection = withReveal(styled.div`

`, <Fade bottom/>)

const TextSection = styled.div`
  text-align: ${(props) => props.isTabletOrMobile ? "center" : "right"};
  margin-bottom: 7%;
`

const Title = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "6.5vw" : "3.77vw"};
  color: #27251f;
  line-height: 1.39;
  letter-spacing: -0.5px;
  margin-bottom: 4%;
`

const GradientTitle = styled.span`
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SubTitle = styled.div`
  white-space: pre-line;
  font-size: ${(props) => props.isTabletOrMobile ? "1rem" : "1.43vw"};
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #27251f;
  font-family: "NotoSansKR Regular", sans-serif;
`

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GraphImage = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "75%" : "90%"};
`

const MobileSubTitle = styled.div`
  font-size: 3.7vw;
  font-family: "NotoSansKR Regular", sans-serif;
  color: #27251f;
  line-height: 1.56;
  letter-spacing: -0.2px;
  margin-top: 25.5px;
`