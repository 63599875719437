import React from "react";
import styled from 'styled-components'
import MobileVideoImg from '../../../media/images/Tech/MobileVideoImg.png'
import ModalVideo from 'react-modal-video'

export const MobileVideoSection = () => {
  const [isVideoModal, setIsVideoModal] = React.useState(false);

  return(
    <Container>

      <ModalVideo
        isOpen={isVideoModal}
        videoId="717786044"
        onClose={() => setIsVideoModal(false)}
        channel='vimeo'
        vimeo={{
          autoplay: true,

        }}
      />

      <ImageWrapper />

      <VideoImage src={MobileVideoImg} />

      <TextSection>
        <Title data-aos="fade-up" data-aos-anchor-placement="center-bottom" >
          {`How to Get
        Just Made Fresh
        & Hot Food`}
        </Title>

        <VideoButton data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"  onClick={() => setIsVideoModal(true)}>
          시청하기
        </VideoButton>

      </TextSection>

    </Container>
  )
}

const Container = styled.div`

`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  position: absolute;
  opacity: 60%;
`

const VideoImage = styled.img`
  width: 100%;
  height: 100vh;
`

const TextSection = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 15%;
  left: 8%;
`

const Title = styled.div`
  color: #ffffff;
  font-size: 9.4vw;
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  line-height:  1.36;
  letter-spacing: -0.3px;
`

const VideoButton = styled.div`
  width: 180px;
  height: 50px;
  border-radius: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "NotoSansKR Medium", sans-serif;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.5px;
  margin-top: 11%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  
  &:hover {
    opacity: 80%;
  }
`