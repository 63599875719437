import React from "react";
import styled from 'styled-components'
import {Row, Col} from 'antd';
import GetCook from '../../../media/images/Tech/GetCook.webp'
import {useMediaQuery} from "react-responsive";
import {Parallax} from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';

export const CookEnRoute = ({parallaxImageLoad}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isTabletMode = useMediaQuery({query: '(min-width: 768px)'});
  // const isMobileMode = useMediaQuery({query: '(max-width: 768px)'});

  return(
    isTabletOrMobile ?

    <Container isTabletOrMobile={isTabletOrMobile}>
      <MobileTextSection>
        <Title isTabletOrMobile={isTabletOrMobile}>
          {`언제 어디서나\n최상의 음식을.\n`}
          <GradientTitle>
            Cook-en-route
          </GradientTitle>
        </Title>

        {/*<ImageSection>*/}
        {/*  <GetCookImage src={MobileGetCook} onLoad={parallaxImageLoad} isTabletOrMobile={isTabletOrMobile}/>*/}
        {/*</ImageSection>*/}

        <SubTitle isTabletOrMobile={isTabletOrMobile} >
          {`Cookaround 트럭은 주문과 동시에\n배달장소로 이동하며 음식을 조리하는 시스템\n‘Cook-en-route’로,  신선하고 따뜻한 음식을\n전달합니다.`}
        </SubTitle>
      </MobileTextSection>
    </Container>

      :

    <Container>
      <Row gutter={[0, 24]}>

        {/* ****************************** IMAGE ****************************** */}
        <Col xs={24} md={12}>
          <Parallax speed={10}  opacity={[0, 3]} >
            <ImageSection>
              <GetCookImage src={GetCook} onLoad={parallaxImageLoad} />
            </ImageSection>
          </Parallax>
        </Col>



        {/* ****************************** TEXT ****************************** */}
        <Col xs={24} md={12}>
          <Parallax speed={-5} opacity={[0, 3]} >
            <TextSection>
              <Title isTabletOrMobile={isTabletOrMobile}>
                {`언제 어디서나\n최상의 음식을\n`}
                <GradientTitle>
                  Cook-en-route
                </GradientTitle>
              </Title>
              <SubTitle isTabletOrMobile={isTabletOrMobile}>
                {`신스타의 Cookaround 트럭은\n주문과 동시에 배달장소로 이동하며\n음식을 조리하는 시스템 ‘Cook-en-route’로\n가장 신선하고 따뜻한 음식을 전달합니다.`}
              </SubTitle>
            </TextSection>
          </Parallax>
        </Col>

      </Row>
    </Container>
  )
}

const Container = styled.div`
  background-color: #F0F0F0;
  padding: ${(props) => props.isTabletOrMobile ? '15% 5%' : '10%'};
  display: flex;
`

const ImageSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const GetCookImage = styled.img`
  width: ${(props) => props.isTabletOrMobile ? '100%' : '75%'};
`

const TextSection = styled.div`

`

const MobileTextSection = withReveal(styled.div`

`, <Fade bottom />)

const Title = styled.p`
  font-size: ${(props) => props.isTabletOrMobile ? '6.5vw' : '3.06vw'};
  white-space: pre-line;
  font-family: "NotoSansKR Bold",sans-serif;
  font-weight: bold;
  line-height: 1.35;
  color: #27251f
`

const GradientTitle = styled.span`
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SubTitle = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Regular",sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? '4vw' : "1.43vw"};
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #27251f;
  margin-top: ${(props) => props.isTabletOrMobile ? '24px' : null};
`

// 3.8vw