import React from "react";
import { ShareHeader } from "../../blocks/Layouts/ShareHeader";
import { ShareFooter } from "../../blocks/Layouts/ShareFooter";
import { VideoSection } from "../../blocks/TechBlocks/VideoSection";
import { Difference } from "../../blocks/TechBlocks/Difference";
import { BrandLinks } from "../../blocks/TechBlocks/BrandLinks";
import { SideNav } from "../../blocks/Layouts/SideNav";
import { AmericanDeliveryProblem } from "../../blocks/TechBlocks/AmericanDeliveryProblem";
import { CookEnRoute } from "../../blocks/TechBlocks/CookEnRoute";
import { PeristalsisApp } from "../../blocks/TechBlocks/PeristalsisApp";
import { AutoDispatch } from "../../blocks/TechBlocks/AutoDispatch";
import { EfficientKitchen } from "../../blocks/TechBlocks/EfficientKitchen";
import { MobileAutoKitchen } from "../../blocks/TechBlocks/MobileAutoKitchen";
import { useParallaxController } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";
import { MobileVideoSection } from "../../blocks/TechBlocks/MobileVideoSection";
import { NewParadigm } from "../../blocks/TechBlocks/NewParadigm";
// import {AutoWok} from "../../blocks/TechBlocks/AutoWok";
import styled from "styled-components";
import olhsoLogo from "../../../../src/media/images/Brand/olhsoLogo.png";

export const Tech = () => {
  const parallaxController = useParallaxController();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    console.log(position);
    setScrollPosition(position);
  };

  const handleNavigate = (url) => {
    window.location.href = url;
  };

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // updates cached values after image dimensions have loaded
  // const parallaxImageLoad = () => parallaxController.update();

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className={"TechPage"}>
      <ShareHeader navPath={"tech"} />
      <Container>
        <MainTitle>
          ⚒️ 공사중
          <SubTitle>Under Construction. See you soon.</SubTitle>
        </MainTitle>
        <Content>
          <SubTitle2>visit our USA brand homepage</SubTitle2>
          <SubTitle2>
            ∨<br />
            ∨<br />∨
          </SubTitle2>
          <ImageContainer>
            <ImageContainer1>
              <Image src={olhsoLogo} alt="olhsotruck" />
              <ImageText1>OLHSO Korean BBQ & Seafood</ImageText1>
              <ImageText2
                onClick={() => handleNavigate("https://olhsotruck.com")}
              >
                OLHSO Truck
              </ImageText2>
              <ImageText3
                onClick={() => handleNavigate("https://olhsohouse.com")}
              >
                OLHSO House
              </ImageText3>
            </ImageContainer1>

            {/* <ImageContainer1
              onClick={() => handleNavigate("https://olhsotruck.com")}
            >
              <Image src={olhsoLogo} alt="olhsotruck" />
              <ImageText1>OLHSO TRUCK</ImageText1>
            </ImageContainer1>
            <ImageContainer2
              onClick={() => handleNavigate("https://olhsohouse.com")}
            >
              <Image src={olhsoLogo} alt="olhsohouse" />
              <ImageText2>OLHSO HOUSE</ImageText2>
            </ImageContainer2> */}
          </ImageContainer>
        </Content>
      </Container>
      {/* <SideNav navPath={"tech"} />
      {isTabletOrMobile ? (
        <MobileVideoSection />
      ) : (
        <VideoSection scrollPosition={scrollPosition} />
      )}
      <AmericanDeliveryProblem />
      <NewParadigm parallaxImageLoad={parallaxImageLoad} />
      <CookEnRoute parallaxImageLoad={parallaxImageLoad} />
      <PeristalsisApp parallaxImageLoad={parallaxImageLoad} />
      <AutoDispatch parallaxImageLoad={parallaxImageLoad} />
      <Difference />
      <EfficientKitchen />
      <MobileAutoKitchen /> */}
      {/* 추후 업데이트 예정 */}
      {/*<AutoWok/>*/}
      {/* <BrandLinks /> */}
      <Line />
      <ShareFooter />
    </div>
  );
};

// 스타일
const Line = styled.div`
  width: 90vw; // 화면 넓이를 꽉 채우게 설정
  height: 1px; // 선의 높이
  background-color: #fff; // 선의 색상 (흰색)
  margin-bottom: 1px; // 선 아래에 여백 추가
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: #121317;
  width: "100%";
  height: "100%";
`;

const MainTitle = styled.div`
  color: #fff;
  font-size: 10rem;
  font-family: "NotoSansKR Bold", sans-serif;
  padding-top: 500px;
  margin-bottom: 300px;
  background-color: #121317;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.div`
  color: #fff;
  font-size: 5rem;
  font-family: "NotoSansKR Bold", sans-serif;
  background-color: #121317;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 300px;
`;

const SubTitle2 = styled.div`
  font-family: "NotoSansKR Bold", sans-serif;
  color: #fff;
  font-size: 3rem;
  background-color: #121317;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -300px; */
  padding-bottom: 300px;
`;

const ImageContainer = styled.div`
  background-color: #121317;
  width: 100vw;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 500px;
  padding-top: 150px;
`;

const ImageContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */
  flex: 1;
  /* margin-top: -500px; */
  /* margin-right: -500px; */
  justify-content: center;
  align-items: center;
`;

const ImageContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-top: -500px;
  margin-left: -500px;
`;

const Content = styled.div`
  padding-bottom: 200px;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 500px;
  border-radius: 8px;
`;

const ImageText1 = styled.div`
  color: #fff;
  font-family: "NotoSansKR Bold", sans-serif;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  margin-top: -100px;
  margin-bottom: 50px;
  /* font-family: "NotoSansKR Bold", sans-serif;
  color: #fff;
  font-size: 3rem;
  background-color: #121317;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 500px; */
`;

const ImageText2 = styled.div`
  color: #fff;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  margin-top: 50px;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #df3f35;
  }
`;

const ImageText3 = styled.div`
  color: #fff;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #babcc5;
  }
`;
