import React from "react";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";

import StrongMan from '../../../../media/images/Hack/StrongMan.png'
import StrongFemale from '../../../../media/images/Hack/StrongFemale.webp'

export const HackTwo = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return(
    <Container>
      <HackTwoImg src={StrongMan} isTabletOrMobile={isTabletOrMobile}/>
      <HeroText isTabletOrMobile={isTabletOrMobile}>용사여 일어나세요...!!</HeroText>
      <HackTwoImgAnother src={StrongFemale} isTabletOrMobile={isTabletOrMobile}/>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const HackTwoImg = styled.img`
  width: ${(props) => props.isTabletOrMobile ? null :  '100%'};
  height: ${(props) => props.isTabletOrMobile && '100%'};
`

const HeroText = styled.div`
  position: absolute;
  font-size: 50px;
  font-weight: bold;
  top: ${(props) => props.isTabletOrMobile ? '68%' : '55%'};
  right: 2%;
`

const HackTwoImgAnother = styled.img`
  width: ${(props) => props.isTabletOrMobile ? '350px' : '500px'};
  position: absolute;
  bottom: 0;
  right: 0;
  
`