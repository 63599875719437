import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LogoImg from "../../../media/images/logo-sinstarr.png";
import { useMediaQuery } from "react-responsive";
import { Menu, Dropdown } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useSetRecoilState } from "recoil";
import { DrawerVisibleAtom } from "../../../recoil";
import upGryImg from "../../../media/icons/arrows/up-gry.png";
import downGryImg from "../../../media/icons/arrows/down-gry.png";
import upBluImg from "../../../media/icons/arrows/up-red.png";
import downBluImg from "../../../media/icons/arrows/down-red.png";
// import {SelectLanguage} from "../../atoms/SelectLanguage";

export const ShareHeader = ({ navPath }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const setDrawerVisible = useSetRecoilState(DrawerVisibleAtom);
  const [changeArrowIcon, setChangeArrowIcon] = useState(false);

  const onVisibleChange = (data) => {
    setChangeArrowIcon(data);
  };

  const menu = (
    <Menu style={{ background: "rgba(255, 255, 255, 0.9)" }}>
      <Menu.Item>
        <Link to={"/news"}>
          <NavTitle>NEWS</NavTitle>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={"/investor-relations"}>
          <NavTitle>IR</NavTitle>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>
      {/* LOGO IMAGE */}
      <a href={"/"}>
        <ImageItem src={LogoImg} />
      </a>

      {/* HEADER LINK */}
      {/* {isTabletOrMobile ? (
        <MenuOutlined
          style={{ fontSize: "1.8em", color: "#e42313" }}
          onClick={() => setDrawerVisible(true)}
        />
      ) : (
        <NavList>
          <Link to={"/"}>
            <NavTitle navPath={navPath === "tech"}>TECH</NavTitle>
          </Link>
          <Link to={"/about"}>
            <NavTitle navPath={navPath === "about"}>ABOUT</NavTitle>
          </Link>
          <Link to={"/brand"}>
            <NavTitle navPath={navPath === "brand"}>BRAND</NavTitle>
          </Link>
          <Dropdown
            overlay={menu}
            placement="bottomCenter"
            onVisibleChange={(visible) => onVisibleChange(visible)}
          >
            <NavTitle navPath={navPath === "media"}>
              MEDIA
              {navPath === "media" ? (
                <ArrowImg src={changeArrowIcon ? upBluImg : downBluImg} />
              ) : (
                <ArrowImg src={changeArrowIcon ? upGryImg : downGryImg} />
              )}
            </NavTitle>
          </Dropdown> */}

      {/* 추후 업데이트 예정 */}
      {/*<SelectLanguage/>*/}
      {/* </NavList>
      )} */}
    </Container>
  );
};

const Container = styled.header`
  width: 100%;
  height: 60px;
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: #121317;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.isTabletOrMobile ? "space-between" : "space-between"};
  padding: ${(props) => (props.isTabletOrMobile ? "0 5%" : "0 10%")};
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
`;

const ImageItem = styled.img`
  width: 150px;
`;

const NavList = styled.div`
  display: flex;
`;

const NavTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: "DMSans Bold", sans-serif;
  color: ${(props) => (props.navPath ? "#e42313" : "#424751")};
  letter-spacing: 1.54px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 78px;
`;

const ArrowImg = styled.img`
  width: 0.6em;
  margin-left: 0.3em;
`;
