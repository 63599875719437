import React from "react";
import styled from 'styled-components'
import MoreImg from '../../media/images/Tech/ic-more.png'
import {useMediaQuery} from "react-responsive";

export const MemberCard = ({data}) => {
  const [somethingHack, setSomethingHack] = React.useState(false);
  const [countHack, setCountHack] = React.useState(0);
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

// 개인 청탁 코드
  React.useEffect(() => {
    if(data.korean_name === "이주호" || data.korean_name === "백민선"){
      setSomethingHack(true);
    }
  }, [])

  // 개인 청탁 코드
  React.useEffect(() => {
    if(countHack >= 10 && data.korean_name === "이주호") {
      window.location.replace('/10qjszmfflrdlwngh');
      setCountHack(0);
    } else if(countHack >= 15 && data.korean_name === "백민선") {
      window.location.replace('/10qjsqwerwqefasdfgasdfasefzmfflrdlwngh');
      setCountHack(0);
    }
  }, [countHack])


  return (
    <Container data={data.specification} onClick={() => {
      if(somethingHack){
        setCountHack(countHack + 1)
      }
    }}>

      {/* MEMBER CARD IMAGE */}
      <ImageSection isTabletOrMobile={isTabletOrMobile}>
        {data.specification && <ImageWrapper/>}
        <ProfileImg
          src={data.mb_img ? data.mb_img : 'https://ww.namu.la/s/59da2ed4ef7eb055deb95547d1612daa4f45a5143706fe7063aef91af8ee7d4f1ff13169fbbfcace07ce787b41170e10d6ea10629fdfd2e79272ef8927c40a929a4a54e7230054cbeb302806b754b164'}/>
        {data.specification &&
          <SpecSection>
            {data.specification.map(spec => {
              return <SpecText>{`• ${spec}`}</SpecText>
            })}
          </SpecSection>
        }
        {data.specification && <MoreIcon src={MoreImg} />}
      </ImageSection>

      {/* MEMBER CARD TEXT */}
      <TextSection>
        <Affiliation jobTitle={data.mb_job_title}>{data.affiliation}</Affiliation>
        <MbJobTitle>{data.mb_job_title}</MbJobTitle>
        <NameSection>
          <KoName>
            {data.korean_name}
          </KoName>
          <EnName>
            {data.english_name}
          </EnName>
        </NameSection>
        {/*<EmailSection>{data.email}</EmailSection>*/}
      </TextSection>

    </Container>
  )
}


const ImageSection = styled.div`
  width: 180px;
  height: 240px;
  border-radius: 20px;
  position: relative;
`

const ImageWrapper = styled.div`
  backdrop-filter: blur(20px);
  background-color: rgba(39, 37, 31, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  top: 0;
  opacity: 0;
`

const SpecSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  opacity: 0;
`

const SpecText = styled.div`
  color: white;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  opacity: 0;
`

const Container = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @keyframes showWrapper {
    0%{
      opacity: 0;
    }
    
    100%{
      opacity: 1;
    } 
  }
  
  &:hover ${ImageWrapper}{
    animation: ${(props) => props.data && 'showWrapper 1s forwards' }
  }

  &:hover ${SpecSection}{
    animation: ${(props) => props.data && 'showWrapper 1s forwards' }
  }

  &:hover ${SpecText}{
    animation: ${(props) => props.data && 'showWrapper 1s forwards' }
  }
`

const MoreIcon = styled.img`
  position: absolute;
  z-index: 2;
  width: 24px;
  bottom: 12px;
  right: 12px;
`

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`

const TextSection = styled.div`
  width: 100%;
  margin-top: 3%;
`

const Affiliation = styled.div`
  font-size: 1rem;
  font-family: "DMSans Bold", sans-serif;
  color: #DA291C;
  line-height: 1.71;
  letter-spacing: 0.09px;
  margin-bottom: ${(props) => props.jobTitle.length === 0 && '28px'};
`

const MbJobTitle = styled.div`
  font-size: 1rem;
  font-family: "NotoSansKR Bold", sans-serif;
  color: #DA291C;
  line-height: 1.75;
  letter-spacing: 0.5px;
`

const NameSection = styled.div`
  display: flex;
  align-items: flex-end;
  
`

const KoName = styled.div`
  font-size: 1rem;
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.5px;
  margin-right: 8px;
`

const EnName = styled.div`
  font-size: 15px;
  font-family: "DMSans Regular", sans-serif;
  line-height: 1.71;
  letter-spacing: 0.09px;
`

// const EmailSection = styled.div`
//   font-size: 1em;
//   font-family: "DMSans Medium", sans-serif;
// `