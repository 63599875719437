import React from "react";
import styled from 'styled-components'
import ShinstarrVideo from '../../../media/videos/shinstarrMainVideo.mp4'
import {useMediaQuery} from "react-responsive";


export const VideoSection = ({scrollPosition}) => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (

          <Container isTabletOrMobile={isTabletOrMobile} scrollPosition={scrollPosition}>

            <video autoPlay loop muted width={'100%'} playsInline={true}>
              <source src={ShinstarrVideo} type="video/mp4"/>
            </video>

          </Container>

  )
}

const Container = styled.div`
  width: 100%;
  position: sticky;
  top:0;
  left: 0;
  background-color: #F0F0F0;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  opacity: ${(props) =>  props.scrollPosition < 700 ? 1 - (props.scrollPosition / 700) : 0 };
`

// const GradientWrapper = styled.div`
//   background-image: linear-gradient(0deg, #E5E5E5 0%, rgba(229, 229, 229, 0) 28.15%);
//   width: 100%;
//   position: absolute;
//   height: 100%;
//   bottom: 0;
// `

