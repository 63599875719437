import React from "react";
import styled from 'styled-components';
import {useMediaQuery} from "react-responsive"
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';

export const JoinUs = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>

      <FadeWrapper>
        <Title isTabletOrMobile={isTabletOrMobile}>채용 공고</Title>

        <TextBody>{`신스타 프리젠츠와 함께하실\n인재를 모집합니다!`}</TextBody>

        <GoButton>바로가기</GoButton>
      </FadeWrapper>

    </Container>
  )
}

const Container =  styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.isTabletOrMobile ? '27% 5%' : '10% 5%'};
  background-color: white;
`

const FadeWrapper = withReveal(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`, <Fade bottom />)

const Title = styled.div`
  font-family: "NotoSansKR Bold", sans-serif;
  width: 100%;
  text-align: center;
  font-size: ${(props) => props.isTabletOrMobile ? '6.5vw' : '2.9rem'};
  letter-spacing: -0.03px;
  line-height: 1.45;
  margin-bottom: 15%;
`

const TextBody = styled.div`
  white-space: pre-line;
  text-align: center;
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.5;
  letter-spacing: -0.5px;
  font-size: 1.2rem;
`

const GoButton = styled.div`
  width: 180px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #27251F;
  color: white;
  font-family: "NotoSansKR Regular", sans-serif;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 30px;
  margin-top: 40px;
  cursor: pointer;
  
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #27251F;
    border: 1px solid black;
  }
`