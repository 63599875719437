import React from "react";
import styled from "styled-components";
import {useParams} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {API_URL} from "../../../configs/Util";

export const IRPaperBody = () => {
  const [irInfo, setIrInfo] = React.useState({});
  const {id} = useParams();
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

  const onIRQuery = () => {
    axios.get(`${API_URL}/ir`).then(res => {
      setIrInfo(res.data.data.find(data => data.ir_index === Number(id)))
    })
  }

  React.useEffect(() => {
    onIRQuery();
  }, [])


  return (
    <Component isTabletOrMobile={isTabletOrMobile}>

      <PaperBodySection isTabletOrMobile={isTabletOrMobile}>
        <PaperTitle isTabletOrMobile={isTabletOrMobile}>{irInfo.ir_title}</PaperTitle>

        {irInfo.sub_title && <PaperSubTitle>{irInfo.sub_title}</PaperSubTitle>}

        {irInfo.ir_content && <PaperText>{irInfo.ir_content}</PaperText>}

        {irInfo.ir_img && <PaperImg src={irInfo.ir_img}/>}

      </PaperBodySection>

    </Component>
  )
}

const Component = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? '10% 5%' : '10%'};
`

const PaperTitle = styled.div`
  color: #000000;
  text-align: center;
  font-size: ${(props) => props.isTabletOrMobile ? '2rem' : "3.1rem"};
  font-family: "DMSans Bold", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  padding-top: 90px;
`

const PaperBodySection = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? null : '0 20%'};
`

const PaperSubTitle = styled.div`
  font-family: "NotoSansKR Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.39;
  letter-spacing: -0.1px;
  color: #000000;
  margin-bottom: 4%;
`

const PaperText = styled.div`
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.5px;
  margin-bottom: 5%;
`

const PaperImg = styled.img`
  width: 100%;
`