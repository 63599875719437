import React from "react";
import styled from "styled-components";
import {Row, Col} from 'antd';

import ICRight from '../../media/images/ic-right.png'
import {useMediaQuery} from "react-responsive";
import moment from "moment";

export const NewsCard = ({item}) => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

  return (
    <>
      {/* NEWS HEADER */}
      <Row gutter={[16]}>
        <Col>
          <NewsName>{item.news_paper}</NewsName>
        </Col>
        <Col>
          <Date>{moment(item.news_date).format('YYYY.MM.DD')}</Date>
        </Col>
      </Row>


      {/* NEWS TITLE */}
      <Row gutter={[8]}>
        <Col md={20}>
          <NewsBody>
            <a href={item.address}>
              <NewsLink>{item.news_title}</NewsLink>
            </a>
          </NewsBody>
        </Col>
        {!isTabletOrMobile &&
          <Col md={4}>
            <a href={item.address}>
              <NewsLinkIcon isTabletOrMobile={isTabletOrMobile} src={ICRight}/>
            </a>
          </Col>
        }
      </Row>
    </>
  )
}

const Date = styled.span`
  color: #bec2cc;
  font-family: "DMSans Regular", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
`

const NewsBody = styled.div`
  margin-bottom: 7%;
`

const NewsName = styled.span`
  color: #df3032;
  font-family: "NotoSansKR Medium", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
`

const NewsLink = styled.div`
  color: #181818;
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.5;
  letter-spacing: -0.02px;
  font-size: 1rem;
`

const NewsLinkIcon = styled.img`
  width: ${(props) => props.isTabletOrMobile ? '1.3rem' : '1.5rem'};
`