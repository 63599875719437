import React from 'react';
import './index.css';
import 'antd/dist/antd.min.css';
import "react-modal-video/css/modal-video.css";
import 'aos/dist/aos.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import {ParallaxProvider} from 'react-scroll-parallax';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <ParallaxProvider>
      <RecoilRoot>
        <App/>
      </RecoilRoot>
    </ParallaxProvider>
  </BrowserRouter>
  , rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
