import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';

// CHIPS
import tag1 from '../../../media/images/About/Chips/tag-1.png'
import tag2 from '../../../media/images/About/Chips/tag-2.png'
import tag3 from '../../../media/images/About/Chips/tag-3.png'
import tag4 from '../../../media/images/About/Chips/tag-4.png'
import tag5 from '../../../media/images/About/Chips/tag-5.png'
import tag6 from '../../../media/images/About/Chips/tag-6.png'
import tag7 from '../../../media/images/About/Chips/tag-7.png'
import tag2m from '../../../media/images/About/Chips/tag-2m.png'
import tag3m from '../../../media/images/About/Chips/tag-3m.png'
import tag4m from '../../../media/images/About/Chips/tag-4m.png'
import tag6m from '../../../media/images/About/Chips/tag-6m.png'

// IMAGE
import LogoBlockImg from "../../../media/images/About/MissionBlockLogo.png"

export const OurMission = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>

      <RowSection isTabletOrMobile={isTabletOrMobile}>
        <CardContainer isTabletOrMobile={isTabletOrMobile}>
          <TitleSection>
            <Title isTabletOrMobile={isTabletOrMobile}>
              OUR MISSION
            </Title>

            <CardBody isTabletOrMobile={isTabletOrMobile}>
              {`우리의 ‘무인 자동화키친’을
              적용한 외식 브랜드들을 통해
              다양하고 훌륭한 한식을
              전세계에 제공하겠습니다.`}
            </CardBody>

          </TitleSection>
        </CardContainer>

        <CardContainer isTabletOrMobile={isTabletOrMobile}>
          <TitleSection>
            <Title isTabletOrMobile={isTabletOrMobile}>
              OUR VISION
            </Title>

            <CardBody isTabletOrMobile={isTabletOrMobile}>
              {`우리는 세상에서 가장 사랑받고, 
              신뢰받으며 가장 빠르게 성장하는 
              한식 브랜드들을 창조하고 배출할 
              것입니다.`}
            </CardBody>
          </TitleSection>
        </CardContainer>
      </RowSection>

      <RowSection isTabletOrMobile={isTabletOrMobile}>
        <CardContainer isTabletOrMobile={isTabletOrMobile}>
          <TitleSection>
            <Title isTabletOrMobile={isTabletOrMobile}>
              {`OUR 
              CORE VALUES`}
            </Title>

            {isTabletOrMobile ?
              <ChipSection>
                <ChipImageSection>
                  <ChipImg src={tag1} isTabletOrMobile={isTabletOrMobile} />
                  <ChipImg src={tag7} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
                <ChipImageSection>
                  <ChipImg src={tag2m} isTabletOrMobile={isTabletOrMobile} />
                  <ChipImg src={tag6m} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
                <ChipImageSection>
                  <ChipImg src={tag4m} isTabletOrMobile={isTabletOrMobile} />
                  <ChipImg src={tag3m} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
                <ChipImageSection>
                  <ChipImg src={tag5} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
              </ChipSection>
            :
              <ChipSection>
                <ChipImageSection>
                  <ChipImg src={tag1} isTabletOrMobile={isTabletOrMobile} />
                  <ChipImg src={tag2} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
                <ChipImageSection>
                  <ChipImg src={tag3} isTabletOrMobile={isTabletOrMobile} />
                  <ChipImg src={tag4} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
                <ChipImageSection>
                  <ChipImg src={tag5} isTabletOrMobile={isTabletOrMobile} />
                  <ChipImg src={tag6} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
                <ChipImageSection>
                  <ChipImg src={tag7} isTabletOrMobile={isTabletOrMobile} />
                </ChipImageSection>
              </ChipSection>
            }

          </TitleSection>
        </CardContainer>

        {!isTabletOrMobile && <LogoImageBlock src={LogoBlockImg} />}

      </RowSection>

    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  padding: ${(props) => props.isTabletOrMobile ? "15%" : "10%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RowSection = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isTabletOrMobile ? "column" : "row"};
  align-items: center;
`

const Title = styled.div`
  font-size: ${(props) => props.isTabletOrMobile ? "4.2vw" : "36px"};
  font-family: "DMSans Bold", sans-serif;
  line-height: 1.2;
  letter-spacing: 0.25px;
  background-image: linear-gradient(to right, #ff1400 -3%, #ff3d00 105%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: pre-line;
}
`

const TitleSection = styled.div`
  width: 100%;
`

const CardContainer = withReveal(styled.div`
  height: ${(props) => props.isTabletOrMobile ? "60.7vw" : "480px"};
  width: ${(props) => props.isTabletOrMobile ? "48.6vw" : "384px"};
  background-color: #ffffff;
  border-radius: ${(props) => props.isTabletOrMobile ? "12px" : "20px"};
  padding: ${(props) => props.isTabletOrMobile ? "15% 5%" : "70px 30px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
  box-shadow: 0 7px 10px 2px rgba(66, 71, 81, 0.3);

  //justify-content: center;
`, <Fade bottom/>)

const CardBody = styled.div`
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "3.8vw" : "23px"};
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #27251f;
  margin-top: ${(props) => props.isTabletOrMobile ? "15%" : "72px"};
  white-space: ${(props) => props.isTabletOrMobile ? "none" : "pre-line"};
`

const ChipImageSection = styled.div`
  display: flex;
`

const ChipSection = styled.div`
  margin-top: 36px;
`

const ChipImg = styled.img`
  height: ${(props) => props.isTabletOrMobile ? "6vw" : "40px"};
  margin-bottom: 8px;
  margin-right: 9px;
`

const LogoImageBlock = withReveal(styled.img`
  width: 410px;
  height: 513px;
`, <Fade bottom/>)

// const CardTitle = styled.div`
//   font-family: "DMSans Bold",sans-serif;
//   font-size: 2rem;
//   line-height: 1.2;
//   letter-spacing: 0.25px;
//   margin-bottom: 10%;
// `


