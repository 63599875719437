import React from 'react'
import styled from "styled-components";

import HackImageSrc from '../../../../media/images/Hack/Hack.gif'
import {useMediaQuery} from "react-responsive";

export const Hack = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <Container>
      <HackImg isTabletOrMobile={isTabletOrMobile} src={HackImageSrc}/>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const HackImg = styled.img`
  width: ${(props) => props.isTabletOrMobile ? null :  '100%'};
  height: ${(props) => props.isTabletOrMobile && '100%'};
`