import React from "react";
import styled from 'styled-components'
import DispatchMapImage from "../../../media/images/Tech/DispatchMap.webp"
import MobileDispatchMapImage from "../../../media/images/Tech/MobileDispatchMap.png"
import PhoneDeviceImage from "../../../media/images/Tech/PhoneDevice.webp"
import {useMediaQuery} from "react-responsive";
import {Parallax} from 'react-scroll-parallax';
import withReveal from "react-reveal/withReveal";
import Fade from 'react-reveal/Fade';

export const AutoDispatch = ({parallaxImageLoad}) => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});
  const isTabletOrMobileText = useMediaQuery({query: '(max-width: 1224px)'});

  return (
    isTabletOrMobile ?

      <Container isTabletOrMobile={isTabletOrMobile}>

        <MobileSection>
          <Title isTabletOrMobile={isTabletOrMobile}>
            <GradientTitle>
              자동 배차 프로세스
            </GradientTitle>
            {`로\n언제 어디든 막힘없이 빠르게`}
          </Title>
          <ImageSection>
            <PhoneDevice isTabletOrMobile={isTabletOrMobile} src={PhoneDeviceImage} onLoad={parallaxImageLoad}/>
            <MapImage src={MobileDispatchMapImage} onLoad={parallaxImageLoad} isTabletOrMobile={isTabletOrMobile}/>
          </ImageSection>
        </MobileSection>

      </Container>

      :

      <Container>

        {/* ******************************* TITLE ******************************* */}
        <Parallax speed={10} opacity={[0, 2]} scale={[0.5, 1]} easing={'easeOutCubic'}>
          <Title isTabletOrMobile={isTabletOrMobile}>{`자동 배차 프로세스로\n언제 어디든 막힘없이 빠르게`}</Title>
        </Parallax>

        {/* ******************************* IMAGE BODY START ******************************* */}
        <Parallax speed={15}>
          <ImageSection>
            <Parallax speed={isTabletOrMobileText ? -5 : -20} className={"PhoneDevice_Parallax"}>
              <PhoneDevice isTabletOrMobile={isTabletOrMobile} src={PhoneDeviceImage} onLoad={parallaxImageLoad}/>
            </Parallax>

            {!isTabletOrMobileText &&
              <Parallax className={"TextSection_Parallax"} easing={'easeInOutBack'}>
                <TextSection>
                  <TextSectionTitle>주문 예측 & 자동 배차 AI</TextSectionTitle>
                  <TextSectionBody>
                    {`실시간 조리 상황 및 식자재 재고, 교통\n상황 등과 함께 과거 주문 기록을 활용한\n빅데이터 분석 AI 기능\n(특허 등록)`}
                  </TextSectionBody>
                </TextSection>
              </Parallax>
            }
            <MapImage src={DispatchMapImage} onLoad={parallaxImageLoad}/>
          </ImageSection>
        </Parallax>
        {/* ******************************* IMAGE BODY END ******************************* */}

      </Container>
  )
}

const Container = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? "30% 0" : "10% 0"};
  background-color: ${(props) => props.isTabletOrMobile ? "white" : "#F0F0F0"};
`

const MobileSection = withReveal(styled.div`

`, <Fade bottom/>)

const Title = styled.p`
  font-size: ${(props) => props.isTabletOrMobile ? '6.5vw' : '3.76vw'};
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  font-weight: bold;
  line-height: 1.39;
  color: #27251f;
  text-align: center;
  letter-spacing: -0.5px;
`

const GradientTitle = styled.span`
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ImageSection = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MapImage = styled.img`
  width: 100%;
  padding: ${(props) => props.isTabletOrMobile ? '15% 0' : '7% 0'};
`

const PhoneDevice = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "36.7vw" : "19.7vw"};
  position: ${(props) => props.isTabletOrMobile ? "absolute" : null};
`

const TextSection = styled.div`
  //position: absolute;
  //right: 16%;
  //top: 60%;
`

const TextSectionTitle = styled.div`
  background-image: linear-gradient(98deg, #f31e0c 42%, #ff4d00 100%);
  font-size: ${(props) => props.isTabletOrMobileText ? '2rem' : '1.5vw'};
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.5;
  margin-bottom: 16px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const TextSectionBody = styled.div`
  color: #27251f;
  font-size: 1.04vw;
  line-height: 1.5;
  letter-spacing: -0.5px;
  font-family: "NotoSansKR Regular", sans-serif;
  white-space: pre-line;
  
`