import React from "react";
import styled from 'styled-components'
import {Row, Col} from 'antd';
import GrapPhone from "../../../media/images/Tech/GrapPhone.webp";
import MobileGrapPhone from "../../../media/images/Tech/MobileGrapPhone.webp";
import {Parallax} from 'react-scroll-parallax';
import {useMediaQuery} from "react-responsive";
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';

export const PeristalsisApp = ({parallaxImageLoad}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (

    isTabletOrMobile ?

      <Container isTabletOrMobile={isTabletOrMobile}>
        <MobileSection>
          <TextSection isTabletOrMobile={isTabletOrMobile}>
            <Title isTabletOrMobile={isTabletOrMobile}>
              <GradientTitle>
                App과 연동
              </GradientTitle>
              {`하여\n터치 한번으로 편리하게`}
            </Title>
          </TextSection>

          <ImageSection>
            <GrapPhoneImage src={MobileGrapPhone} onLoad={parallaxImageLoad}/>
          </ImageSection>

          {/*<SubTitle isTabletOrMobile={isTabletOrMobile}>*/}
          {/*  {`모바일에서 터치 한번으로\n간편하게 주문이 가능합니다.`}*/}
          {/*</SubTitle>*/}
        </MobileSection>
      </Container>

      :

    <Container>
      <Row gutter={[0, 48]}>

        {/* ****************************** TEXT ****************************** */}
        <Col xs={24} md={12}>
          <Parallax speed={-5} opacity={[0, 2]}>
            <TextSection>
              <Title isTabletOrMobile={isTabletOrMobile}>
                <GradientTitle>
                  App과 연동
                </GradientTitle>
                {`하여\n터치 한번으로 편리하게`}
              </Title>
              <SubTitle isTabletOrMobile={isTabletOrMobile}>
                {`전용 앱으로 모일에서 터치 한번으로\n간편하게 주문이 가능합니다.`}
              </SubTitle>
            </TextSection>
          </Parallax>
        </Col>

        {/* ****************************** IMAGE ****************************** */}
        <Col xs={24} md={12}>
          <Parallax speed={15} opacity={[0, 2.5]}>
            <ImageSection>
              <GrapPhoneImage src={GrapPhone} onLoad={parallaxImageLoad}/>
            </ImageSection>
          </Parallax>
        </Col>

      </Row>
    </Container>
  )
}

const Container = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? '10% 5%' : '5% 10%'};
  background-color: ${(props) => props.isTabletOrMobile ? 'white' : '#F0F0F0'};
`

const MobileSection = withReveal(styled.div`

`, <Fade bottom />)

const ImageSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const GrapPhoneImage = styled.img`
  width: 75%;
  margin-left: 5%;
`

const TextSection = styled.div`
  text-align: ${(props) => props.isTabletOrMobile ? 'center' : 'right'};
  padding-top: 14%;
`

const Title = styled.p`
  font-size: ${(props) => props.isTabletOrMobile ? '6.5vw' : '3.06vw'};
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  font-weight: bold;
  line-height: 1.35;
  color: #27251f;
`

const GradientTitle = styled.span`
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SubTitle = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? '3.8vw' : '1.43vw'};
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #27251f;
  text-align: ${(props) => props.isTabletOrMobile ? 'center' : 'right'};
  margin-top: ${(props) => props.isTabletOrMobile ? '19px' : null};
`



