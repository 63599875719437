import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import mintPigImg from "../../../media/images/Brand/mintPigBack.png"
import mobileMintPigImg from "../../../media/images/Brand/MobileMinpi.png"
import minpiImg from "../../../media/images/Brand/minpi.png";

export const MintPig = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    <Component isTabletOrMobile={isTabletOrMobile} className={"brandMintPigContiner"} mintPigImg={mintPigImg}>

      <BackGroundImage isTabletOrMobile={isTabletOrMobile} src={isTabletOrMobile ? mobileMintPigImg : mintPigImg} loading={'lazy'}/>

      <TitleImage src={minpiImg} isTabletOrMobile={isTabletOrMobile}/>

      <TextSection isTabletOrMobile={isTabletOrMobile}>
        <Contents isTabletOrMobile={isTabletOrMobile}>
          {isTabletOrMobile ?
            `한국인의 영원한 소울푸드이자 패스트푸드인 분식을 글로벌시장으로 세계화!

              자율주방시스템을 통하여 누구나 쉽게 운영할 수 있는 1인 운영 분식 프랜차이즈 입니다.
              운영비를 절감하여 가맹점주의 수익 극대화시켜드립니다.`
            :
            `한국인의 영원한 소울푸드이자 패스트푸드인 분식을 글로벌시장으로 세계화!
            세계 어느 나라에서나 누구나 쉽게 운영할 수 있는 1인 운영 분식 프랜차이즈 입니다.

            자율주방시스템을 통하여 운영비를 절감하여 가맹점주의 수익 극대화시켜드립니다.`
          }
        </Contents>
      </TextSection>

    </Component>
  )
}

const BackGroundImage = styled.img`
  position: absolute;
  height: 100vh;
  opacity: 1;
  
`

const TextSection = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? "0 5%" : "0 37%"};
  position: relative;
  opacity: 0;
  text-align: center;
`

const TitleImage = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "260px" : "310px"};
  margin-bottom: 66px;
  position: relative;
  opacity: 0;
`

const Component = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 70px;
  


  @keyframes BackGroundImageAni {
    0%{
      opacity: 1;
    }
    
    100%{
      opacity: 0.4;
      filter: blur(15px);
    }
  }
  
  @keyframes TextSectionAni {
    0%{
      opacity: 0;
    }
    100% {
      position: relative;
      opacity: 1;
    }
  }
  
  
  &:hover ${BackGroundImage} {
    animation: BackGroundImageAni 1s forwards;
  }

  &:hover ${TextSection} {
    animation: TextSectionAni 1s forwards;
  }

  &:hover ${TitleImage} {
    animation: TextSectionAni 1s forwards;
  }
`

const Contents = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "16px" : "1.4rem"};
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: #fff
`

