import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import UpArrow from "../../../media/images/Tech/ic-arrow-up.png";
import { useMediaQuery } from "react-responsive";
import AOS from "aos";

export const AmericanDeliveryProblem = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  React.useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>
      {/* ******************************* TITLE SECTION ******************************* */}
      <TitleSection isTabletOrMobile={isTabletOrMobile}>
        <Title
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          isTabletOrMobile={isTabletOrMobile}
        >
          {isTabletOrMobile
            ? `미국의 배달 음식은\n값은 가장 비싸면서도\n음식의 품질은\n가장 낮은걸로 유명하죠.`
            : `미국 배달 음식 시장의\n 심각한 문제점`}
        </Title>

        {!isTabletOrMobile && (
          <SubTitle
            isTabletOrMobile={isTabletOrMobile}
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            {
              <span>
                {`미국의 배달 음식은 배달비 정책으로 인해 `}
                <SubTitleWithColor>
                  {`값은 가장 비싸면서도\n음식의 품질은 가장 낮은걸로 유명하죠.\n`}
                </SubTitleWithColor>
                이는 갈수록 심각해질 수 밖에 없는 구조적인 문제점입니다.
              </span>
            }
          </SubTitle>
        )}
      </TitleSection>

      {/* ******************************* CARDS SECTION START ******************************* */}
      <div style={{ padding: isTabletOrMobile ? "0 10%" : null }}>
        <Row gutter={[18, 48]}>
          <Col xs={24} sm={24} md={isTabletOrMobile ? 12 : 6}>
            <CardSection
              isTabletOrMobile={isTabletOrMobile}
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {isTabletOrMobile
                  ? `2021 미국의\n배달음식 시장 규모`
                  : `2021 미국의\n배달음식 시장 규모`}
              </CardTitle>
              <CardBody>
                <CardOneText isTabletOrMobile={isTabletOrMobile}>
                  $285억
                </CardOneText>
              </CardBody>
            </CardSection>
          </Col>

          <Col xs={24} sm={24} md={isTabletOrMobile ? 12 : 6}>
            <CardSection
              isTabletOrMobile={isTabletOrMobile}
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {isTabletOrMobile
                  ? `2020 미국의\n배달침투율`
                  : `2020 미국의\n배달침투율`}
              </CardTitle>
              <CardBody>
                <CardTwoTextSection>
                  <CardTwoMainText isTabletOrMobile={isTabletOrMobile}>
                    24%
                  </CardTwoMainText>
                  <CardTwoSubText isTabletOrMobile={isTabletOrMobile}>
                    배달 플랫폼-고객 배달
                  </CardTwoSubText>
                </CardTwoTextSection>
                <CardTwoTextSection>
                  <CardTwoMainText isTabletOrMobile={isTabletOrMobile}>
                    18%
                  </CardTwoMainText>
                  <CardTwoSubText isTabletOrMobile={isTabletOrMobile}>
                    레스토랑-고객 직접 배달
                  </CardTwoSubText>
                </CardTwoTextSection>
              </CardBody>
            </CardSection>
          </Col>

          <Col xs={24} sm={24} md={isTabletOrMobile ? 12 : 6}>
            <CardSection
              isTabletOrMobile={isTabletOrMobile}
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {isTabletOrMobile
                  ? `공급 보다 많은 수요`
                  : `공급 보다 많은 수요`}
              </CardTitle>
              <CardBody>
                <CardThreeTextSection isTabletOrMobile={isTabletOrMobile}>
                  <CardThreeText isTabletOrMobile={isTabletOrMobile}>
                    배달 수수료
                  </CardThreeText>
                  <CardThreeArrowImage
                    src={UpArrow}
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </CardThreeTextSection>
                <CardThreeTextSection isTabletOrMobile={isTabletOrMobile}>
                  <CardThreeText isTabletOrMobile={isTabletOrMobile}>
                    배달 소요 시간
                  </CardThreeText>
                  <CardThreeArrowImage
                    src={UpArrow}
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </CardThreeTextSection>
                <CardThreeTextSection isTabletOrMobile={isTabletOrMobile}>
                  <CardThreeText isTabletOrMobile={isTabletOrMobile}>
                    배달 음식 비용
                  </CardThreeText>
                  <CardThreeArrowImage
                    src={UpArrow}
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </CardThreeTextSection>
                <CardThreeTextSection isTabletOrMobile={isTabletOrMobile}>
                  <CardThreeText isTabletOrMobile={isTabletOrMobile}>
                    배달 종사자 임금
                  </CardThreeText>
                  <CardThreeArrowImage
                    src={UpArrow}
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </CardThreeTextSection>
              </CardBody>
            </CardSection>
          </Col>

          <Col xs={24} sm={24} md={isTabletOrMobile ? 12 : 6}>
            <CardSection
              isTabletOrMobile={isTabletOrMobile}
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <CardTitle isTabletOrMobile={isTabletOrMobile}>
                {isTabletOrMobile
                  ? `배달 서비스별\n음식점 판매가와의 차이`
                  : `배달 서비스별\n음식점 판매가와의 차이`}
              </CardTitle>
              <CardBody>
                <CardFourTextSection isTabletOrMobile={isTabletOrMobile}>
                  <CardFourKeySection>
                    <CardFourTextKey isTabletOrMobile={isTabletOrMobile}>
                      음식점 가격
                    </CardFourTextKey>
                    <CardFourTextKey isTabletOrMobile={isTabletOrMobile}>
                      Uber Eats
                    </CardFourTextKey>
                    <CardFourTextKey isTabletOrMobile={isTabletOrMobile}>
                      Caviar
                    </CardFourTextKey>
                    <CardFourTextKey isTabletOrMobile={isTabletOrMobile}>
                      Doordash
                    </CardFourTextKey>
                  </CardFourKeySection>
                  <CardFourValueSection isTabletOrMobile={isTabletOrMobile}>
                    <CardFourTextValue isTabletOrMobile={isTabletOrMobile}>
                      $50
                    </CardFourTextValue>
                    <CardFourTextValue isTabletOrMobile={isTabletOrMobile}>
                      + 29.7%
                    </CardFourTextValue>
                    <CardFourTextValue isTabletOrMobile={isTabletOrMobile}>
                      + 32.2%
                    </CardFourTextValue>
                    <CardFourTextValue isTabletOrMobile={isTabletOrMobile}>
                      + 32.8%
                    </CardFourTextValue>
                  </CardFourValueSection>
                </CardFourTextSection>
              </CardBody>
            </CardSection>
          </Col>
        </Row>
      </div>
      {/* ******************************* CARDS SECTION END ******************************* */}

      {!isTabletOrMobile && (
        <AmericanDeliveryStandard
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          *미국 배달산업 기준
        </AmericanDeliveryStandard>
      )}

      {/* ******************************* MOBILE END TITLE START ******************************* */}
      {isTabletOrMobile && (
        <EndTitleSection isTabletOrMobile={isTabletOrMobile} data-aos="fade-up">
          <EndTitle isTabletOrMobile={isTabletOrMobile}>
            {`이는 갈수록 심각해질\n수 밖에 없는\n구조적인 문제점입니다.`}
          </EndTitle>
        </EndTitleSection>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) =>
    props.isTabletOrMobile ? "#F3F3F5" : "white"};
  padding: ${(props) => (props.isTabletOrMobile ? "10% 5%" : "0 10%")};
  scroll-snap-align: start;
`;

const TitleSection = styled.div`
  padding: ${(props) => (props.isTabletOrMobile ? null : "0 10%")};
  text-align: ${(props) => (props.isTabletOrMobile ? "center" : "left")};
`;

const Title = styled.div`
  white-space: pre-line;
  color: #27251f;
  font-size: ${(props) => (props.isTabletOrMobile ? "6.5vw" : "3.6rem")};
  line-height: ${(props) => (props.isTabletOrMobile ? "1.55" : "1.39")};
  font-family: "NotoSansKR Bold", sans-serif;
  font-weight: bold;
  letter-spacing: -0.5px;
  margin-bottom: ${(props) => (props.isTabletOrMobile ? "30%" : "40px")};
  padding-top: ${(props) => (props.isTabletOrMobile ? "20%" : "5%")};
  opacity: 1;
  //animation: FadeUp 1s forwards;
  //
  //@keyframes FadeUp {
  //  0%{
  //    transform: translateY(100px);
  //    opacity: 0;
  //  }
  //
  //  100%{
  //    transform: translateY(0);
  //    opacity: 1;
  //  }
  //}
`;

const SubTitle = styled.div`
  color: #27251f;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => (props.isTabletOrMobile ? "3.7vw" : "22px")};
  line-height: 1.5;
  letter-spacing: -0.5px;
  white-space: pre-line;
  margin-bottom: 205px;
  opacity: 1;
  //animation: FadeUp 1s 0.3s forwards;
  //
  //@keyframes FadeUp {
  //  0%{
  //    transform: translateY(100px);
  //    opacity: 0;
  //  }
  //
  //  100%{
  //    transform: translateY(0);
  //    opacity: 1;
  //  }
  //}
`;

const SubTitleWithColor = styled.span`
  font-family: "NotoSansKR Bold", sans-serif;
  background-image: linear-gradient(110deg, #f31e0c 42%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CardSection = styled.div`
  text-align: ${(props) => (props.isTabletOrMobile ? "center" : "center")};
  height: ${(props) => (props.isTabletOrMobile ? "300px" : "23vw")};
  background-color: ${(props) =>
    props.isTabletOrMobile ? "#ffffff" : "#f3f3f5"};
  border-radius: 20px;
  box-shadow: 2px 2px 13px 1px rgba(66, 71, 81, 0.2);
  padding: ${(props) => (props.isTabletOrMobile ? "5% 2%" : "5% 10%")};
  opacity: 60%;
  //animation: FadeUp 1s 0.6s forwards;
  //
  //@keyframes FadeUp {
  //  0%{
  //    transform: translateY(100px);
  //    opacity: 0;
  //  }
  //
  //  100%{
  //    transform: translateY(0);
  //    opacity: 1;
  //  }
  //}
`;

const CardTitle = styled.div`
  color: ${(props) => (props.isTabletOrMobile ? "#A9A8A5" : "#27251f")};
  white-space: pre-line;
  font-family: "NotoSansKR Bold", sans-serif;
  font-weight: bold;
  line-height: ${(props) => (props.isTabletOrMobile ? "24px" : "1.5")};
  letter-spacing: -0.5px;
  height: 20%;
  font-size: ${(props) => (props.isTabletOrMobile ? "16px" : "1.24vw")};
  // margin-bottom:  ${(props) => (props.isTabletOrMobile ? "16px" : null)};
`;

const CardBody = styled.div`
  display: flex;
  justify-content: center;
  height: 80%;
  flex-direction: column;
`;

const CardOneText = styled.div`
  font-family: "DMSans Bold", sans-serif;
  font-size: ${(props) => (props.isTabletOrMobile ? "48px" : "3.1vw")};
  letter-spacing: -0.5px;
  line-height: 1.5;
  background-image: linear-gradient(110deg, #f31e0c 42%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CardTwoTextSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 0;
`;

const CardTwoMainText = styled.div`
  font-family: "DMSans Bold", sans-serif;
  font-size: ${(props) => (props.isTabletOrMobile ? "36px" : "2.35vw")};
  font-weight: bold;
  line-height: 1.39;
  letter-spacing: -0.5px;
  background-image: linear-gradient(110deg, #f31e0c 42%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CardTwoSubText = styled.div`
  color: #27251f;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => (props.isTabletOrMobile ? "17px" : "1.04vw")};
  line-height: 1.5;
  letter-spacing: -0.5px;
  //opacity: 60%;
`;

const CardThreeTextSection = styled.div`
  display: flex;
  align-items: center;
  padding: 3% 0;
  justify-content: ${(props) => (props.isTabletOrMobile ? "center" : "center")};
`;

const CardThreeText = styled.div`
  font-size: ${(props) => (props.isTabletOrMobile ? "20px" : "1.43vw")};
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.5;
  color: #262520;
  font-weight: bold;
`;
const CardThreeArrowImage = styled.img`
  width: ${(props) => (props.isTabletOrMobile ? "17px" : "1.1vw")};
  margin-left: 3%;
`;

const CardFourTextSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isTabletOrMobile ? "center" : "center")};
`;

const CardFourKeySection = styled.div`
  text-align: left;
`;

const CardFourValueSection = styled.div`
  margin-left: ${(props) => (props.isTabletOrMobile ? "5%" : "10%")};
`;

const CardFourTextKey = styled.div`
  font-size: ${(props) => (props.isTabletOrMobile ? "17px" : "1.1vw")};
  color: #27251f;
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.5px;
  padding: ${(props) => (props.isTabletOrMobile ? "8px" : "0.65vw 0")};
`;

const CardFourTextValue = styled.div`
  font-size: ${(props) => (props.isTabletOrMobile ? "22px" : "1.4vw")};
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.35;
  letter-spacing: -0.1px;
  padding: ${(props) => (props.isTabletOrMobile ? "8px 0" : "0.68vw 0")};
  text-align: left;
  background-image: linear-gradient(110deg, #f31e0c 42%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AmericanDeliveryStandard = styled.div`
  color: #8e9197;
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.5px;
  margin-top: 36px;
  padding-bottom: 9%;
`;

const EndTitleSection = styled.div`
  padding: ${(props) => (props.isTabletOrMobile ? null : "0 10%")};
  text-align: ${(props) => (props.isTabletOrMobile ? "center" : "left")};
`;

const EndTitle = styled.div`
  white-space: pre-line;
  color: #262520;
  font-size: ${(props) => (props.isTabletOrMobile ? "6.6vw" : "3.5rem")};
  line-height: ${(props) => (props.isTabletOrMobile ? "1.55" : "1.39")};
  font-family: "NotoSansKR Bold", sans-serif;
  font-weight: bold;
  letter-spacing: -0.5px;
  margin-bottom: ${(props) => (props.isTabletOrMobile ? "15%" : "40px")};
  padding-top: ${(props) => (props.isTabletOrMobile ? "35%" : "188px")};
  opacity: 1;
  //animation: FadeUp 1s forwards;
  //
  //@keyframes FadeUp {
  //  0%{
  //    transform: translateY(100px);
  //    opacity: 0;
  //  }
  //
  //  100%{
  //    transform: translateY(0);
  //    opacity: 1;
  //  }
  //}
`;
