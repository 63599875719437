import React from "react";
import styled from 'styled-components'
import RedTruck from '../../../media/images/Tech/RedTruck.webp'
import MobileRedTruck from '../../../media/images/Tech/MobileRedTruckImg.webp'
import {useMediaQuery} from "react-responsive";
import {Parallax} from 'react-scroll-parallax';

export const NewParadigm = ({parallaxImageLoad}) => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return(

    <Container>

      <Parallax speed={-10}>
        <Title isTabletOrMobile={isTabletOrMobile}>
          {`Cook-en-route\n새로운\n배달 패러다임`}
        </Title>
      </Parallax>

      <div style={{width: '100%'}}>
        <Parallax speed={5}>
          <TruckImage isTabletOrMobile={isTabletOrMobile} src={isTabletOrMobile ? MobileRedTruck : RedTruck} alt={"Red-Truck-Image"} onLoad={parallaxImageLoad} />
        </Parallax>
      </div>

    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-family: "NotoSansKR Bold", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "9.9vw" : "86px" };
  line-height: 1.26;
  letter-spacing: -1.5px;
  background-image: linear-gradient(119deg, #f31e0c 43%, #ff4d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: pre-line;
  text-align: center;
  padding: 115px 0;
`

const TruckImage = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "96%" : "70%"};
  position: relative;
  bottom: ${(props) => props.isTabletOrMobile ? null : "-70px"};
  left: ${(props) => props.isTabletOrMobile ? null : "15%"};
`