import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import OlhsoImage from '../../../media/images/Brand/logo-olhso.png'
import MobileOlhsoImage from '../../../media/images/Brand/MobileOlhso.png'
import OlhsoBack from "../../../media/images/Brand/olhsoBack.png";

export const KoreanBBQ = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

  return (
    <Component isTabletOrMobile={isTabletOrMobile}>

      <BackGroundImage isTabletOrMobile={isTabletOrMobile} src={isTabletOrMobile ? MobileOlhsoImage : OlhsoBack} loading={'lazy'}/>

      <TitleImage src={OlhsoImage} isTabletOrMobile={isTabletOrMobile}/>

      <TextSection isTabletOrMobile={isTabletOrMobile}>
        <Contents isTabletOrMobile={isTabletOrMobile}>
          {
            isTabletOrMobile ?
              `미국에서 가장 사랑받는 한국식 고깃집을 신개념 요식사업모델로 구축하였습니다. 

                플래그십 고정형 매장을 통해 한국식 바베큐의 정수를 보여주며, 자동 조리 시스템을 탑제한 푸드트럭을 통해 따뜻한 음식을 빠르게 배달합니다.`
              :
            `미국에서 가장 사랑받는 한국식 고깃집을 신개념 요식사업모델로 구축하였습니다. 

            플래그십 고정형 매장 (OLHSO Korean BBQ Steakhouse & Bar)을 통해 한국식 바베큐의 정수를 보여주며,
            자동 조리 시스템을 탑제한 푸드트럭 (OLHSO Korean BBQ Express) 을 통해 배달장소로 이동하며 자동으로 음식을 조리하여 가장 신선하고 따뜻한 음식을 빠르게 배달합니다.`
          }
        </Contents>
        <SubContents isTabletOrMobile={isTabletOrMobile}>{`*미국 캘리포니아 산마테오(San Mateo County)에서 2023년 상반기 런칭 예정`}</SubContents>
      </TextSection>

    </Component>
  )
}

const BackGroundImage = styled.img`
  position: absolute;
  height: 100vh;
  opacity: 1;
`

const TextSection = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? "0 8%" : "0 31%"};
  position: relative;
  text-align: ${(props) => props.isTabletOrMobile ? "center" : "left"};
  opacity: 0;
`

const TitleImage = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "39%" : "12%"};
  margin-bottom: 66px;
  position: relative;
  opacity: 0;
`

const Component = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @keyframes BackGroundImageAni {
    0%{
      opacity: 1;
    }

    100%{
      opacity: 0.4;
      filter: blur(15px);
    }
  }

  @keyframes TextSectionAni {
    0%{
      opacity: 0;
    }
    100% {
      position: relative;
      opacity: 1;
    }
  }

  &:hover ${BackGroundImage} {
    animation: BackGroundImageAni 1s forwards;
  }

  &:hover ${TextSection} {
    animation: TextSectionAni 1s forwards;
  }

  &:hover ${TitleImage} {
    animation: TextSectionAni 1s forwards;
  }
`


const Contents = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "16px" : "1.4rem"};
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: #fff;
`

const SubContents = styled.div`
  font-size: ${(props) => props.isTabletOrMobile ? "14px" : "16px"};
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #fff;
  margin-top: 40px;
  opacity: 70%;
`