import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";
import ChickenImg from '../../../media/images/Brand/brandChickenBack.png'
import MobileChickenImg from '../../../media/images/Brand/MobileChickFarm.png'
import ChickFarm from "../../../media/images/Brand/just-made-chick-farm.png";

export const Chicken = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

  return (
    <Component isTabletOrMobile={isTabletOrMobile}>

      <BackGroundImage isTabletOrMobile={isTabletOrMobile} src={isTabletOrMobile ? MobileChickenImg : ChickenImg} loading={'lazy'}/>

      <TitleImage src={ChickFarm} isTabletOrMobile={isTabletOrMobile}/>

      <TextSection isTabletOrMobile={isTabletOrMobile}>
        <Contents isTabletOrMobile={isTabletOrMobile}>
          {
            isTabletOrMobile ?
              `미국인의 취향에 맞게 윙과 텐더, 너겟으로 구성한 신개념 치킨 브랜드 입니다.

               고정형 매장을 통해  테이크아웃과 배달전문점을 운영하며, 자동 조리 시스템을 탑제한 푸드트럭을 통해 따뜻한 음식을 빠르게 배달합니다.`
              :
            `미국 슈퍼볼에서 하루동안  소비되는 치킨 윙의 개수만 무려 20억개!

             미국인의 소울푸드인 치킨을 미국인의 취향에 맞게 윙과 텐더, 너겟으로 구성한 신개념 치킨 브랜드 입니다.
             고정형 매장 (JustMade ChickFarm) 을 통해 테이크아웃과 배달전문점을 운영하며,
            자동 조리 시스템을 탑제한 푸드트럭 (JustMade ChickTruck) 을 통해 배달장소로 이동하며 자동으로 음식을 조리하여, 가장 신선하고 따뜻한 음식을 빠르게 배달합니다.`
          }
        </Contents>
        <SubContents isTabletOrMobile={isTabletOrMobile}>{`*미국 캘리포니아 산마테오(San Mateo County)에서 2023년 상반기 런칭 예정`}</SubContents>
      </TextSection>

    </Component>
  )
}

const BackGroundImage = styled.img`
  position: absolute;
  height: 100vh;
  opacity: 1;
`

const TextSection = styled.div`
  padding: ${(props) => props.isTabletOrMobile ? "0 8%" : "0 30%"};
  position: relative;
  text-align: ${(props) => props.isTabletOrMobile ? "center" : "left"};
  opacity: 0;
`

const TitleImage = styled.img`
  width: ${(props) => props.isTabletOrMobile ? "50%" : "18%"};
  margin-bottom: 72px;
  position: relative;
  opacity: 0;
`

const Component = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;

  @keyframes BackGroundImageAni {
    0%{
      opacity: 1;
    }

    100%{
      opacity: 0.4;
      filter: blur(15px);
    }
  }

  @keyframes TextSectionAni {
    0%{
      opacity: 0;
    }
    100% {
      position: relative;
      opacity: 1;
    }
  }

  &:hover ${BackGroundImage} {
    animation: BackGroundImageAni 1s forwards;
  }

  &:hover ${TextSection} {
    animation: TextSectionAni 1s forwards;
  }

  &:hover ${TitleImage} {
    animation: TextSectionAni 1s forwards;
  }
`

const SubContents = styled.div`
  font-size: ${(props) => props.isTabletOrMobile ? "14px" : "16px"};
  font-family: "NotoSansKR Regular", sans-serif;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #fff;
  margin-top: 40px;
  opacity: 70%;
`

const Contents = styled.div`
  white-space: pre-line;
  font-family: "NotoSansKR Regular", sans-serif;
  font-size: ${(props) => props.isTabletOrMobile ? "16px" : "1.4rem"};
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: #fff;
`