import React, { useState } from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import styled from "styled-components";
import { MemberCard } from "../../atoms/MemberCard";
import { useMediaQuery } from "react-responsive";
import { TeamMemberData } from "../../../configs/TeamMemberData";

export const TeamMember = ({ teamMember }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [isData, setIsData] = React.useState(false); // 서버를 통한 데이터와 View 포인트의 비동기화로 인한 조정 상태값
  const [cardQty, setCartQty] = useState(0);

  React.useEffect(() => {
    if (teamMember?.length > 0) {
      setIsData(true);
    }
  }, [teamMember]);

  React.useEffect(() => {
    let qty = Number(window.innerWidth) / 250;
    setCartQty(qty);
  }, [isTabletOrMobile]);

  return (
    <Container isTabletOrMobile={isTabletOrMobile}>
      {/* <Title isTabletOrMobile={isTabletOrMobile}>멤버 소개</Title>

      <CarouselSection isTabletOrMobile={isTabletOrMobile}>
        {isData ?
          <Carousel
            show={cardQty}
            slide={1}
            swiping={true}
            swipeOn={0.2}
            dynamic={true}
            useArrowKeys={true}
          >
            {teamMember.map((data, index) => {
              return (<MemberCard key={`${data.english_name}MemberCard${index}`} data={data}/>)
            })}
          </Carousel>
          :
          <Carousel
            show={cardQty}
            slide={1}
            swiping={true}
            swipeOn={0.2}
            dynamic={true}
            useArrowKeys={true}
          >
            {TeamMemberData.map((data, index) => {
              return (<MemberCard key={`${data.english_name}MemberCard${index}`} data={data}/>)
            })}
          </Carousel>
        }
      </CarouselSection> */}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.isTabletOrMobile ? "white" : "#F0F0F0"};
  padding-top: 3%;
  padding-bottom: 150px;
`;

const Title = styled.div`
  font-family: "NotoSansKR Bold", sans-serif;
  text-align: center;
  font-size: ${(props) => (props.isTabletOrMobile ? "6.5vw" : "2.9rem")};
  line-height: 1.45;
  padding: 2% 0;
`;

const CarouselSection = styled.div`
  background-color: ${(props) =>
    props.isTabletOrMobile ? "white" : "#F0F0F0"};
  padding: 3% 5%;
`;
