import React from "react";
import styled from 'styled-components'
import {useMediaQuery} from "react-responsive";

export const IRTitle = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1124px)'});

  return (
    <>
      <Container isTabletOrMobile={isTabletOrMobile}>
        <Title isTabletOrMobile={isTabletOrMobile}>IR</Title>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.isTabletOrMobile ? '15rem' : "30em" };
  padding-top: 70px;
  background-image: linear-gradient(to bottom, rgba(190, 194, 204, 0) 52%, rgba(190, 194, 204, 0.15) 100%);
  margin-bottom: 4%;
`

const Title = styled.span`
  font-size: ${(props) => props.isTabletOrMobile ? '30px' : "3rem" };
  font-family: "DMSans Medium",sans-serif;
  color: #424751;
  font-weight: bold;
  line-height: 1.2;
`