import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// LOGO IMAGE
import MintPIgImg from "../../../media/images/Tech/img-mintpig.png";
import OlhsoImg from "../../../media/images/Tech/img-olhso.png";
import ImgChick from "../../../media/images/Tech/img-chicken.png";
// import GongDoriImg from '../../../media/images/Tech/img-gongdori.png'

export const BrandLinks = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return isTabletOrMobile ? (
    <Continer isTabletOrMobile={isTabletOrMobile}>
      <Title isTabletOrMobile={isTabletOrMobile}>브랜드</Title>

      <MobileCardSection className={"mobileCardSection"}>
        <ImageContainer>
          <ImageItem isTabletOrMobile={isTabletOrMobile} src={MintPIgImg} />
        </ImageContainer>

        <ImageContainer>
          <ImageLink href="https://olhsotruck.com/" target="_blank">
            <ImageItem isTabletOrMobile={isTabletOrMobile} src={OlhsoImg} />
          </ImageLink>
        </ImageContainer>

        <ImageContainer>
          <ImageItem isTabletOrMobile={isTabletOrMobile} src={ImgChick} />
        </ImageContainer>
      </MobileCardSection>
    </Continer>
  ) : (
    <Continer isTabletOrMobile={isTabletOrMobile}>
      <Title isTabletOrMobile={isTabletOrMobile}>브랜드</Title>

      <Row gutter={[0, 16]}>
        {/*<Col xs={12} sm={12} md={6}>*/}
        {/*  <ImageContainer>*/}
        {/*    <ImageItem*/}
        {/*      src={GongDoriImg}/>*/}
        {/*  </ImageContainer>*/}
        {/*</Col>*/}

        <Col xs={12} sm={12} md={8}>
          <ImageContainer>
            <ImageItem isTabletOrMobile={isTabletOrMobile} src={MintPIgImg} />
          </ImageContainer>
        </Col>

        <Col xs={12} sm={12} md={8}>
          <ImageContainer>
            <ImageItem isTabletOrMobile={isTabletOrMobile} src={OlhsoImg} />
          </ImageContainer>
        </Col>

        <Col xs={12} sm={12} md={8}>
          <ImageContainer>
            <ImageItem isTabletOrMobile={isTabletOrMobile} src={ImgChick} />
          </ImageContainer>
        </Col>
      </Row>
    </Continer>
  );
};

const Continer = styled.div`
  padding: ${(props) => (props.isTabletOrMobile ? "10% 5%" : "10%")};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: ${(props) => (props.isTabletOrMobile ? "6.5vw" : "3.77vw")};
  font-family: "NotoSansKR Bold", sans-serif;
  line-height: 1.39;
  letter-spacing: -0.5px;
  color: #282b31;
  text-align: center;
  margin-bottom: 7%;
`;

const MobileCardSection = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  justify-content: space-evenly;
  padding-left: 20%;
`;

const ImageContainer = styled.div`
  border-radius: 20px;
  position: relative;
  background-color: #fff;
  cursor: pointer;
`;

const ImageLink = styled.a`
  display: block; /* 이미지를 클릭 가능한 영역으로 만들기 위해 */
`;

const ImageItem = styled.img`
  width: ${(props) => (props.isTabletOrMobile ? "35.5vw" : "282px")};
`;
